import Vue from 'vue';
import Vuex from 'vuex';
import userModule from "@/store/modules/UserModule";
import headingsModule from "@/store/modules/HeadingsModule";
import languageModule from "@/store/modules/LanguageModule";
import portalModule from "@/store/modules/PortalModule";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        sidebarShow: 'responsive',
        sidebarMinimize: false,
        progressbar: false,
        progressbarVisible: true,
    },
    getters: {
        isProgressbar: state => state.progressbar,
        isProgressbarVisible: state => state.progressbarVisible,
    },
    mutations: {
        SET_PROGRESSBAR: (state, status) => {
            state.progressbar = status;
        },
        SET_PROGRESSBAR_VISIBILITY: (state, status) => {
            state.progressbarVisible = status;
        },

        toggleSidebarDesktop(state) {
            const sidebarOpened = [ true, 'responsive' ].includes(state.sidebarShow)
            state.sidebarShow = sidebarOpened ? false : 'responsive'
        },
        toggleSidebarMobile(state) {
            const sidebarClosed = [ false, 'responsive' ].includes(state.sidebarShow)
            state.sidebarShow = sidebarClosed ? true : 'responsive'
        },
        set(state, [ variable, value ]) {
            state[variable] = value
        }
    },
    actions: {
        showProgressbar: ({ commit }) => commit('SET_PROGRESSBAR', true),
        hideProgressbar: ({ commit }) => commit('SET_PROGRESSBAR', false),
        setProgressbarVisible: ({ commit }) => commit('SET_PROGRESSBAR_VISIBILITY', true),
        setProgressbarInvisible: ({ commit }) => commit('SET_PROGRESSBAR_VISIBILITY', false),
    },
    modules: {
        user: userModule,
        heading: headingsModule,
        language: languageModule,
        portal: portalModule,
    }
})
