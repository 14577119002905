import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'
import { faFileImage } from '@fortawesome/free-solid-svg-icons'
import { faFileExcel } from '@fortawesome/free-solid-svg-icons'
import { faFileWord } from '@fortawesome/free-solid-svg-icons'
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faFilePdf);
library.add(faFileImage);
library.add(faFileExcel);
library.add(faFileWord);
library.add(faTrashAlt);


Vue.component('font-awesome-icon', FontAwesomeIcon)


