import Vue from "vue";
import methods from "@/globals/global-methods";
import Pagination from "@/components/shared/Pagination";
import Modal from "@/components/shared/Modal";

const globalMethods = methods;

Vue.mixin(
    {
        components: {
            Modal, Pagination
        },
        computed: {
            currentLocale() {
                return this.$store.getters['language/getCurrentLocale'];
            },
            translations() {
                return this.$store.getters['language/getTranslations'];
            }
        },
        methods: globalMethods
    }
);