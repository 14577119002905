import authUrls from "@/views/admin/auth/urls";
import levelUrls from "@/views/admin/levels/urls";
import applicationUrls from "@/views/admin/applications/urls";
import languageUrls from "@/views/admin/languages/urls";
import companyUrls from "@/views/admin/companies/urls";
import portalUrls from "@/views/portal/urls";
import userUrls from "@/views/admin/users/urls";
import clientUrls from "@/views/admin/clients/urls";
import couponUrls from "@/views/admin/coupons/urls";
import homeUrls from "@/views/admin/home/urls";

const urls = {
    general: {
        units: {
            url: '/applications/actions/unitOptions'
        },
        fileUpload: {
            url: '/upload'
        }
    },
    auth: authUrls,
    level: levelUrls,
    application: applicationUrls,
    language: languageUrls,
    company: companyUrls,
    portal: portalUrls,
    user: userUrls,
    client:clientUrls,
    coupon:couponUrls,
    home:homeUrls
};

export default urls;