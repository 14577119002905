const portalUrls = {
    countries: {
        url: '/portal/countries'
    },
    airports: {
        url: '/portal/airports'
    },
    cities: {
        url: '/portal/cities'
    },
    serviceParameters: {
        url: '/portal/serviceParameters'
    },
    phoneCodes: {
        url: '/portal/phoneCodes'
    },
    unitOptions: {
        url: '/portal/unitOptions'
    },
    customsDocuments: {
        url: '/portal/customsDocuments'
    },
    companyDocuments: {
        url: '/portal/companyDocuments'
    },
    addDocument: {
        url: '/portal/company/addDocument'
    },
    updateContact: {
        url: '/portal/company/:id/updateContact'
    },
    addBankRequisites: {
        url: '/portal/company/addBankRequisites'
    },
    addServices: {
        url: '/portal/company/addServices'
    },
    addCountries: {
        url: '/portal/company/addCountries'
    },
    addServiceOptions: {
        url: '/portal/company/addServiceOptions'
    },
    services: {
        url: '/portal/services'
    },
    serviceOptions: {
        url: '/portal/serviceOptions'
    },
    sendApplication: {
        url: '/portal/client/sendApplication'
    },
    agentRegister: {
        url: '/portal/agent/register'
    },
    registerCompany: {
        url: '/portal/company/register'
    },
    findApplication: {
        url: '/portal/client/findApplication'
    },
    tracking: {
        url: '/portal/client/tracking'
    },
    clientRegister: {
        url: '/portal/client/register'
    },
    clientContact: {
        url: '/portal/client/:id/contact'
    },
    clientStats: {
        url: '/portal/client/clientStats'
    },
    waitingApps: {
        url: '/portal/client/:id/waitingApps'
    },
    clientApps: {
        url: '/portal/client/:id/apps'
    },
    activeApps: {
        url: '/portal/client/:id/activeApps'
    },
    getApp: {
        url: '/portal/client/:id/getApp'
    },
    clientProfile: {
        url: '/portal/client/account'
    },
    clientUpdate: {
        url: '/portal/client/update'
    },

    clientAddRequisite: {
        url: '/portal/client/:id/addRequisite'
    },
    clientAddCompany: {
        url: '/portal/client/:id/addCompany'
    },
    clientRequisite: {
        url: '/portal/client/:id/requisite'
    },
    clientCompanies: {
        url: '/portal/client/:id/companies'
    },
    contactTypes: {
        url: '/portal/contactTypes'
    },
    addContact: {
        url: '/portal/client/app/:id/addContact'
    },
    contacts: {
        url: '/portal/client/app/:id/contacts'
    },
    requestFor: {
        url: '/portal/client/app/:id/requestFor'
    },
    priceInfo: {
        url: '/portal/client/app/:id/priceInfo'
    }
    ,
    requestCallback: {
        url: '/portal/requestCallback'
    }
};

export default portalUrls;