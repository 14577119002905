import Vue from 'vue'
import Lang from 'vue-lang'
import config from '../config'
import ru from '../locales/ru'

let locales = {
    ru
};
// Проверям если в localStorage есть locale
let language = localStorage.getItem('locale');

if (typeof locales[language] === 'undefined') language = config.locale;

Vue.use(Lang, {lang: language, locales: locales});