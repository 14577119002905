import store from "../../store";
import HelperFunctions from '@/classes/HelperFunctions';
import UserFunctions from "@/classes/UserFunctions";

export default async ({ next, router }) => {

    if ( UserFunctions.getUserToken(true) ) {

        if ( !store.getters['user/getPortalUserId'] ) {
            await HelperFunctions.loadPortalUser().then(() => {
                return router.push({ name: 'portal.home' }).catch(() => {
                });
            }).catch(() => {
                return router.push({ name: 'portal.login' }).catch(() => {
                });
            });
        } else
            return router.push({ name: 'portal.home' }).catch(() => {
            });

    }

    next();
}
