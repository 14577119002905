import {
    cilArrowRight,
    cilBan,
    cilBell,
    cilCheck,
    cilChevronBottom,
    cilChevronTop,
    cilCheckCircle,
    cilLockLocked,
    cilSettings,
    cilTask,
    cilUser,
    cilPlus,
    cilArrowLeft,
    cilDelete,
    cilBookmark,
    cilOptions,
    cilPencil,
    cilMinus, cilSend, cilCash, cilSync, cilAddressBook, cilPeople,
    cilStar,cilPrint,cilEnvelopeLetter,cilClipboard,cilAirplaneMode

} from '@coreui/icons'
import { logo } from './logo'

export const iconsSet = Object.assign(
    {},
    { logo },
    {
        cilArrowLeft,
        cilArrowRight,
        cilBan,
        cilBell,
        cilCheck,
        cilChevronBottom,
        cilChevronTop,
        cilCheckCircle,
        cilLockLocked,
        cilSettings,
        cilTask,
        cilUser,
        cilPlus, cilDelete, cilBookmark,
        cilOptions, cilPencil, cilMinus, cilSend, cilCash, cilSync, cilAddressBook, cilPeople,
        cilStar,cilPrint,cilEnvelopeLetter,cilClipboard,cilAirplaneMode
    }
)
