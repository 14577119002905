const portalModule = {
    namespaced: true,
    state: {
        app: {},
        menu: '',
    },
    getters: {
        getApp: state => state.app,
        getMenu: state => state.menu,
    },
    mutations: {
        SET_APP: (state, value) => {
            state.app = value;
        },
        SET_MENU: (state, value) => {
            state.menu = value;
        },
        SET_CANT_REQUEST_TARIF: (state, value) => {
            state.app.canRequestTarif = false;
        },
        SET_CANT_REQUEST_INVOICE: (state, value) => {
            state.app.canRequestInvoice = false;
        },
    },
    actions: {
        setApp: ({ commit }, value) => {
            commit('SET_APP', value);
        },
        setMenu: ({ commit }, value) => {
            commit('SET_MENU', value);
        },
        resetApp: ({ commit }) => {
            commit('SET_APP', {});
        },
        resetMenu: ({ commit }) => {
            commit('SET_MENU', '');
        },
        setCantRequestTarif: ({ commit }) => {
            commit('SET_CANT_REQUEST_TARIF', false);
        },
        setCantRequestInvoice: ({ commit }) => {
            commit('SET_CANT_REQUEST_INVOICE', false);
        }
    },
};

export default portalModule;