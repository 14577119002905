const clientUrls={
    getList:{
        url:'/clients'
    },
    get:{
        url:'/clients/:id'
    },
    user:{
        url:'/clients/:id/user'
    },
    apps:{
        url:'/clients/:id/apps'
    },
};

export default clientUrls;