import 'core-js/stable'
import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store/index'

import config from "./config";
import urls from './urls/config';
import './globals/config';

// plugins init
import './plugins/vue-axios'
import './plugins/vue-lang'
import './plugins/vue-fa-icons'
import './plugins/vue-moment'
import './plugins/vue-select'
import './plugins/vue-slider'
import './plugins/vue-snotify'
import './plugins/vuelidate'
import './plugins/vue-lazyload'
import './plugins/vue-scrollto'
import './plugins/vue-ckeditor'
import './plugins/vue-pace'
import './plugins/vue-swal'
import './plugins/vue-pusher'
import './plugins/vue-html-paper'
import './plugins/vue-datetimepicker'


Vue.prototype.$config = config;
Vue.config.productionTip = false;
Vue.prototype.$urls = urls;

Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.prototype.$log = console.log.bind(console)

new Vue({
    router,
    store,
    icons,
    render: h => h(App)
}).$mount('#app')
