export default {
    fileUploadService(file) {
        let formData = new FormData;
        formData.append('file', file);
        return this.axios.post(this.$urls.general.fileUpload.url, formData, {
            headers: {
                'Content-Type': `multipart/form-data; boundary=${ formData.boundary }`
            }
        });
    },
}