import Vue from 'vue';
import Snotify, { SnotifyPosition } from 'vue-snotify';

import "../assets/css/snotify.css";

const options = {
    toast: {
        position: SnotifyPosition.rightCenter
    }
};

Vue.use(Snotify, options);