import store from "@/store";
import HelperFunctions from '@/classes/HelperFunctions';
import UserFunctions from "@/classes/UserFunctions";

export default async ({ next, router }) => {

    if ( UserFunctions.getUserToken(true) && !store.getters['user/getPortalUserId'] ) {
        await HelperFunctions.loadPortalUser(true).then(() => {
            return next();
        }).catch(() => {
        });
    }
    return next();
}
