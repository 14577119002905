import Vue from 'vue';
import VueHtmlToPaper from 'vue-html-to-paper';

const options = {
    specs: [
        'fullscreen=yes',
        'titlebar=yes',
        'scrollbars=yes'
    ],
    styles: [
        '/print.css',
        'https://cdn.jsdelivr.net/npm/bootstrap@5.0.1/dist/css/bootstrap.min.css',
        'https://unpkg.com/papercss@1.8.2/dist/paper.min.css'
    ],
    windowTitle: window.document.title, // override the window title
}


Vue.use(VueHtmlToPaper,options);