<template>
    <nav class="pagination">
        <ul class="pagination__list">
            <li v-if="pagination.current_page > 1" class="pagination__item" :title="getTranslation('previous')">
                <button @click="toPage(pagination.current_page-1)" class="pagination__link">
                    <svg class="pagination__arrow">
                        <use xlink:href="#icon__arrow-2-left"></use>
                    </svg>
                </button>
            </li>
            <li v-if="pagination.current_page > 2" class="pagination__item">
                <button @click="toPage(1)" class="pagination__link">1</button>
            </li>
            <li v-if="pagination.current_page > 3" class="pagination__item">
                <button @click="toPage(getMiddlePage(1, pagination.current_page))" class="pagination__link">...</button>
            </li>
            <li v-if="pagination.current_page > 1" class="pagination__item">
                <button @click="toPage(pagination.current_page-1)" type="button" class="pagination__link">{{ pagination.current_page-1 }}</button>
            </li>
            <li class="pagination__item pagination__item--active">
                <button type="button" class="pagination__link">{{ pagination.current_page }}</button>
            </li>
            <li v-if="pagination.last_page !== pagination.current_page" class="pagination__item">
                <button @click="toPage(pagination.current_page+1)" type="button" class="pagination__link">{{ pagination.current_page+1 }}</button>
            </li>
            <li v-if="pagination.last_page - pagination.current_page > 2" class="pagination__item">
                <button @click="toPage(getMiddlePage(pagination.current_page, pagination.last_page))" class="pagination__link">...</button>
            </li>
            <li v-if="pagination.last_page - pagination.current_page > 1" class="pagination__item">
                <button @click="toPage(pagination.last_page)" class="pagination__link">{{ pagination.last_page }}</button>
            </li>
            <li v-if="pagination.current_page < pagination.last_page" class="pagination__item" :title="getTranslation('next')">
                <button @click="toPage(pagination.current_page+1)" class="pagination__link">
                    <svg class="pagination__arrow">
                        <use xlink:href="#icon__arrow-2-right"></use>
                    </svg>
                </button>
            </li>
        </ul>
    </nav>
</template>

<script>
    export default {
        props: {
            value: [Number, String],
            pagination: Object
        },

        methods: {
            toPage(page) {
                this.$emit('input', page);
                this.$emit('change', page);
            },
            getMiddlePage(first, second) {
                return Math.floor((second - first) / 2 + first);
            }
        }
    }
</script>

<style>
    .pagination .pagination__list {
        list-style: none;
    }

    .pagination .pagination__list li {
        display: inline-block;
    }
</style>