import Vue from 'vue'
import store from '../store'

// progress bar
Vue.prototype.$Pace = {
    start() {
        store.dispatch('showProgressbar');
    },
    finish() {
        store.dispatch('hideProgressbar');
    },
    fail() {
        store.dispatch('hideProgressbar');
    }
};
