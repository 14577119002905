const headings = {
    title: '',
    description: '',
    breadcrumbs: [],
    actions: [],
    toBack: {
        label: '',
        route: {}
    }
};

const appInfo = { status: null };
const companyInfo = { status: null };

const headingsModule = {
    namespaced: true,
    state: {
        headings: headings,
        portalHeadings: headings,
        lastRoute: {
            name: '',
        },

        appInfo: appInfo,
        companyInfo: companyInfo

    },
    getters: {
        getHeadings: state => state.headings,
        getPortalHeadings: state => state.portalHeadings,
        getBreadcrumbs: state => state.headings.breadcrumbs,
        getPortalBreadcrumbs: state => state.portalHeadings.breadcrumbs,
        getTitle: state => state.headings.title,
        getActions: state => state.headings.actions,
        getLastRoute: state => state.lastRoute,
        getAppInfo: state => state.appInfo,
        getCompanyInfo: state => state.companyInfo,
    },
    mutations: {
        SET_HEADINGS: (state, headings) => {
            state.headings = headings;
        },
        SET_PORTAL_HEADINGS: (state, headings) => {
            state.portalHeadings = headings;
        },
        SET_LAST_ROUTE: (state, route) => {
            state.lastRoute = route;
        },
        SET_APP_INFO: (state, value) => {
            state.appInfo = value;
        },
        SET_COMPANY_INFO: (state, value) => {
            state.companyInfo = value;
        }
    },
    actions: {
        setHeadings: ({ commit }, value) => commit('SET_HEADINGS', { ...headings, ...value }),
        setPortalHeadings: ({ commit }, value) => commit('SET_PORTAL_HEADINGS', { ...headings, ...value }),
        flushHeadings: ({ commit }) => commit('SET_HEADINGS', headings),
        flushPortalHeadings: ({ commit }) => commit('SET_PORTAL_HEADINGS', headings),
        setLastRoute: ({ commit }, route) => commit('SET_LAST_ROUTE', route),
        setAppInfo: ({ commit }, value) => commit('SET_APP_INFO', value),
        resetAppInfo: ({ commit }) => commit('SET_APP_INFO', appInfo),
        resetCompanyInfo: ({ commit }) => commit('SET_COMPANY_INFO', companyInfo),
        setCompanyInfo: ({ commit }, value) => commit('SET_COMPANY_INFO', value),
    },
};

export default headingsModule;
