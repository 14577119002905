const lang = {
    locale: '',
    languages: {}
};

const languageModule = {
        namespaced: true,
        state: {
            language: lang,
            locales: [],
        },
        mutations: {
            SET_LANGUAGE: (state, language) => {
                state.language = language;
            },
            SET_LOCALES: (state, locales) => {
                state.locales = locales;
            },
        },
        getters: {
            getLanguage: state => state.language,
            getTranslations: state => state.language.translations,
            getCurrentLocale: state => state.language.locale,
            getLocales: state => state.locales,
        },
        actions: {
            setLanguage: ({commit}, value) => {
                commit('SET_LANGUAGE', value);
            },
            setLocales: ({commit}, value) => {
                commit('SET_LOCALES', value);
            }
        }
        ,
    }
;

export default languageModule;