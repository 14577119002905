export default {
    locale: {
        "en": "eng",
        "ru": "рус",
    },
    app: {
        "ky_locale": "ky",
        "ru_locale": "ru",
        "en_locale": "en",
        "close": "Закрыть",
        "ky": "Кыргызча",
        "ru": "Русский",
        "en": "English",
        "home": "Главная",
        "testing": "Тестирование",
        "description": "Социальная парадигма верифицирует современный механизм власти. Согласно классификации М.Вебера, марксизм иллюстрирует либерализм.",
        "saveSuccessMsg": "Успешно сохранено!",
        "noInternetConnection": "Проверьте подключение к интернету",
    },
    datePicker: {
        "mon": "Пн",
        "tue": "Вт",
        "wed": "Ср",
        "thu": "Чт",
        "fri": "Пт",
        "sat": "Сб",
        "sun": "Вс",
        "january": "Январь",
        "february": "Февраль",
        "march": "Март",
        "april": "Апрель",
        "may": "Май",
        "june": "Июнь",
        "july": "Июль",
        "august": "Август",
        "september": "Сентябрь",
        "october": "Октябрь",
        "november": "Ноябрь",
        "december": "Декабрь",
        "nextMonth": "След. месяц",
        "prevMonth": "Пред. месяц",
        "setTime": "Указать время",
        format: "дд.мм.гггг"
    },
    pagination: {
        "previous": "Предыдущая",
        "next": "Следующая"
    },
    languages: {
        "languages": "Переводы",
        "statistics": "Статистика",
        "reports": "Отчеты",
        "key": "Ключ",
        "levels": "Роли",
        "save": "Сохранить",
        "add": "Добавить",
        "cancel": "Отмена",
        "edit": "Редактировать",
        "delete": "Удалить",
        "update": "Обновить",
        "requiredField": "Необходимо заполнить это поле",
        "required": "Обьязательные поля",
        "wrongEmail": "Не правильная эл. почта",
        "wrongFormMsg": "Проверьте правильность введенных данных",
        "fileTypeMustBeImage": "Выбран не правильный файл",
        "saveSuccessMsg": "Успешно сохранено",
        "signOut": "Выйти",
        "signIn": "Войти",
        "authentication": "Авторизация",
        "identification": "Идентификация",
        "fillingExample": "Пример заполнения",
        "photoExample": "Пример фото",
        "yourPhoto": "Ваше фото",
        "settings": "Настройки",
        "pageForbidden": "У вас нет доступа к данной странице",
        "pageNotFound": "Страница не найдена",
        "serverError": "Что то пошло не так, сообщите администратору",
        "error": "Ошибка",
        "close": "Закрыть",
        "ky": "Кыргызча",
        "en": "English",
        "ru": "Русский",
        "home": "Главная",
        "noInternetConnection": "Проверьте подключение к интернету",
        "previous": "Предыдущая",
        "next": "Следующая",
        "actions": "Действия",
        "deleteSuccessMsg": "Успешно удалено!",
        "updateSuccessMsg": "Успешно обновлено!",
        "admin": "Админка",
        "searchPhrase": "Поиск фразы",
        "resultFoundQuantity": "По вашему запросу найдено {0} результатов"
    },
}
