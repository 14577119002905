const companyUrls = {
    companies: {
        url: '/companies'
    },
    company: {
        url: '/companies/:id'
    },
    formOptions: {
        url: '/companies/actions/formOptions'
    },
    filterOptions: {
        url: '/companies/actions/filterOptions'
    },
    requisites: {
        url: '/companies/:id/requisite'
    },
    services: {
        url: '/companies/:id/services'
    },
    applications: {
        url: '/companies/:id/applications'
    },
    agents: {
        url: '/companies/:id/agents'
    },
    serviceOptions: {
        url: '/companies/:id/serviceOptions'
    },
    countries: {
        url: '/companies/:id/countries'
    },
    documents: {
        url: '/companies/:id/documents'
    },
    confirm: {
        url: '/companies/:id/confirm'
    },
    reject: {
        url: '/companies/:id/reject'
    },
    setCoupon: {
        url: '/companies/:id/setCoupon'
    },
    removeCoupon: {
        url: '/companies/:id/removeCoupon'
    },
};

export default companyUrls;