import store from "../../store";
import HelperFunctions from '@/classes/HelperFunctions';

import UserFunctions from "@/classes/UserFunctions";

export default async ( { next, router, to } ) => {
    if ( !UserFunctions.getUserToken(true) )
        return  router.push( { name: 'portal.login' } ).catch(()=>{});

    if ( !store.getters['user/getPortalUserId'] || store.getters['user/getPortalPermissions'].length < 1 )
        await HelperFunctions.loadPortalUser().then( () => {
            if ( store.getters['user/getPortalPermissions'].filter( permission => permission === to.name ).length < 1 )
                return  router.push( { name: 'portal.errors.403' } ).catch(()=>{});
        } ).catch( () => {
            return  router.push( { name: 'portal.login' } ).catch(()=>{});
        } );
    else if ( store.getters['user/getPortalPermissions'].filter( permission => permission === to.name ).length < 1 )
        return router.push( { name: 'portal.errors.403' } ).catch(()=>{});

    return next();
};
