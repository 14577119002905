import store from "@/store";
import router from '../router'
import axios from 'axios';

const PORTAL_TOKEN = 'portal_token';
const USER_TOKEN = 'auth_token';

export default {
    getUserToken(portal = false) {

        if ( portal )
            return localStorage.getItem(PORTAL_TOKEN);

        return localStorage.getItem(USER_TOKEN);
    },

    setUserToken(token, portal = false) {

        let bearerToken = 'Bearer ' + token;

        let tokenName = USER_TOKEN;

        if ( portal )
            tokenName = PORTAL_TOKEN;

        localStorage.setItem(tokenName, bearerToken);
    },

    setAxiosAuthorization(portal = false) {
        axios.defaults.headers.common['Authorization'] = this.getUserToken(portal);
    },


    async removeUserInfo(redirect = true, portal = false) {

        let action = 'user/resetUser';
        let routeName = 'admin.login';
        let tokenName = USER_TOKEN;


        if ( portal ) {
            routeName = 'portal.login';
            action = 'user/resetPortalUser';
            tokenName = PORTAL_TOKEN;
        }

        await localStorage.removeItem(tokenName);

        await store.dispatch(action);

        if ( redirect )
            router.replace({ name: routeName }).catch(() => {
            });

        return new Promise((resolve) => {
            this.setAxiosAuthorization(portal);
            resolve(true);
        });
    },
};