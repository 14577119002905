const userUrls = {
    users: {
        url: '/users'
    },
    notifications:{
        url:'/users/:id/notifications'
    },
    readNotification:{
        url:'/users/:id/readNotification'
    },
};

export default userUrls;