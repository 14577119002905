import store from "../store";
import HelperFunctions from '@/classes/HelperFunctions';
import UserFunctions from "@/classes/UserFunctions";

export default async ( { next, router } ) => {
    if ( UserFunctions.getUserToken() ) {
        if ( !store.getters['user/getUserId'] ) {
            await HelperFunctions.loadUser().then( () => {
                return router.push( { name: 'admin.home' } ).catch(()=>{});
            } ).catch( () => {
                return router.push( { name: 'admin.login' } ).catch(()=>{});
            } );
        }
        else
            return router.push( { path: '/admin' } ).catch(()=>{});
    }
    next();
}
