const user = {
    level: {
        id: null,
        permissions: []
    },
    contact: {},
    agent: {},
    client: {},
    fname: '',
    email: '',
    pname: '',
    username: '',
    lname: '',
    id: null,
    isAgent: false,
};

const userModule = {
    namespaced: true,
    state: {
        user: user,
        portalUser: user,
        isLoggedOut: false,
    },
    getters: {
        getUser: state => state.user,
        getPortalUser: state => state.portalUser,
        getPortalPermissions: state => state.portalUser.level.permissions,
        getPermissions: state => state.user.level.permissions,
        getUserId: state => state.user.id,
        getPortalUserId: state => state.portalUser.id,
        isLoggedOut: state => state.isLoggedOut,
        isClient: state => state.portalUser.level.slug === 'client',
        isAgent: state => state.portalUser.level.slug === 'agent',
    },
    mutations: {
        SET_USER: (state, user) => {
            state.user = user;
        },
        SET_PORTAL_USER: (state, user) => {
            state.portalUser = user;
        },
        SET_LOGOUT_TRUE: (state) => {
            state.isLoggedOut = true;
        },
        SET_LOGOUT_FALSE: (state) => {
            state.isLoggedOut = false;
        },
    },
    actions: {
        setUser: ({ commit }, value) => {
            commit('SET_USER', user);
            commit('SET_USER', value);
        },
        resetUser: ({ commit }) => {
            commit('SET_USER', user);
        },
        setPortalUser: ({ commit }, value) => {
            commit('SET_PORTAL_USER', user);
            commit('SET_PORTAL_USER', value);
        },
        resetPortalUser: ({ commit }) => {
            commit('SET_PORTAL_USER', user);
        },
        logoutUser: ({ commit }) => {
            commit('SET_LOGOUT_TRUE');
        },
        loginUser: ({ commit }) => {
            commit('SET_LOGOUT_FALSE');
        },
    },
};

export default userModule;
