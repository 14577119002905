import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store'
import permission from "@/middlewares/permission";
import portalPermission from "@/middlewares/portal/permission";
import guest from "@/middlewares/guest";
import portalGuest from "@/middlewares/portal/guest";
import auth from "@/middlewares/portal/auth";


Vue.use(Router);

function loadAdminView(view) {
    return () => import(`@/views/admin/${ view }.vue`);
}

function loadView(view) {
    return () => import(`@/views/${ view }.vue`);
}

function loadPortalView(view) {
    return () => import(`@/views/portal/${ view }.vue`);
}

const ForgotPassword = loadView('ForgotPassword');
const ResetPassword = loadView('ResetPassword');

/* admin */
const Home = loadAdminView('home/Index');

//auth
const Login = loadAdminView('auth/Login');
const Logins = loadAdminView('auth/Logins');

//error
const Error404 = loadAdminView('errors/Page404');
const Error403 = loadAdminView('errors/Page403');
const Error500 = loadAdminView('errors/Page500');

//application
const ApplicationCreate = loadAdminView('applications/Create');
const ApplicationIndex = loadAdminView('applications/Index');
const Callbacks = loadAdminView('applications/RequestCallbacks');
const ApplicationView = loadAdminView('applications/View');
const LanguageIndex = loadAdminView('languages/Index');

//level
const Levels = loadAdminView('levels/Index');
const LevelsEdit = loadAdminView('levels/Edit');

//user
const Users = loadAdminView('users/Index');

//client
const Clients = loadAdminView('clients/Index');
const ClientView = loadAdminView('clients/View');

//company
const CompanyIndex = loadAdminView('companies/Index');
const CompanyCreate = loadAdminView('companies/Create');
const CompanyView = loadAdminView('companies/View');

//Coupon
const CouponIndex = loadAdminView('coupons/Index');

//portal
const PortalHome = loadPortalView('Index');
const PortalSendApp = loadPortalView('SendApplication');
const PortalLogin = loadPortalView('auth/Login');
const PortalRegister = loadPortalView('auth/Register');
const PortalRegisterShipper = loadPortalView('company/Create');
const PortalRegisterAgent = loadPortalView('company/CreateAgent');
const PortalRegisterClient = loadPortalView('client/Create');
const PortalClientIndex = loadPortalView('client/Index');
const PortalClientProfile = loadPortalView('client/Profile');
const PortalTracking = loadPortalView('Tracking');
const PortalAboutUs= loadPortalView('About-Us');
const PortalClientAppHistory = loadPortalView('client/AppHistory');

//portal errors
const PortalError403 = loadPortalView('errors/error403');
const PortalError404 = loadPortalView('errors/error404');

const router = new Router({
    mode: 'history',
    linkActiveClass: 'active',
    scrollBehavior: () => ({ y: 0 }),
    routes: [
        {
            path: '/admin',
            component: () => import('@/layouts/admin/Main'),
            children: [
                {
                    path: '',
                    name: 'admin.home',
                    component: Home,
                    meta: {
                        authRequired: true,
                        middleware: permission
                    }
                },

                {
                    path: 'auth/logins',
                    name: 'auth.logins',
                    component: Logins,
                    meta: {
                        middleware: permission,
                        authRequired: true,
                    }
                },

                //levels
                {
                    path: 'levels',
                    name: 'auth.levels.getList',
                    component: Levels,
                    meta: {
                        authRequired: true,
                        middleware: permission
                    }
                },
                {
                    path: 'levels/:id',
                    name: 'auth.levels.update',
                    component: LevelsEdit,
                    meta: {
                        middleware: permission,
                        authRequired: true,
                    }
                },
                //applications
                {
                    path: 'applications',
                    name: 'application.getList',
                    component: ApplicationIndex,
                    meta: {
                        middleware: permission,
                        authRequired: true,
                    }
                },
                {
                    path: 'callbacks',
                    name: 'application.requestCallback.getList',
                    component: Callbacks,
                    meta: {
                        middleware: permission,
                        authRequired: true,
                    }
                },
                {
                    path: 'applications/create',
                    name: 'application.create',
                    component: ApplicationCreate,
                    meta: {
                        middleware: permission,
                        authRequired: true,
                    }
                },
                {
                    path: 'applications/:id',
                    name: 'application.get',
                    component: ApplicationView,
                    meta: {
                        middleware: permission,
                        authRequired: true,
                    }
                },
                {
                    path: 'applications/:id/invoice',
                    name: 'application.profile.invoice',
                    component: loadAdminView('applications/Invoice'),
                    meta: {
                        middleware: permission,
                        authRequired: true,
                    }
                },

                //languages
                {
                    path: 'languages',
                    name: 'lang.getList',
                    component: LanguageIndex,
                    meta: {
                        middleware: permission,
                        authRequired: true,
                    }
                },
                //company
                {
                    path: 'companies',
                    name: 'company.getList',
                    component: CompanyIndex,
                    meta: {
                        middleware: permission,
                        authRequired: true,
                    }
                },
                {
                    path: 'companies/create',
                    name: 'company.create',
                    component: CompanyCreate,
                    meta: {
                        middleware: permission,
                        authRequired: true,
                    }
                },
                {
                    path: 'companies/:id',
                    name: 'company.get',
                    component: CompanyView,
                    meta: {
                        middleware: permission,
                        authRequired: true,
                    }
                },
                //error
                {
                    path: '403',
                    name: 'errors.403',
                    component: Error403,
                    meta: {
                        authRequired: true,
                    }
                },
                {
                    path: '404',
                    name: 'errors.404',
                    component: Error404,
                    meta: {
                        authRequired: true,
                    }
                },
                {
                    path: '500',
                    name: 'errors.500',
                    component: Error500,
                    meta: {
                        authRequired: true,
                    }
                },
                //user
                {
                    path: 'users',
                    name: 'user.getList',
                    component: Users,
                    meta: {
                        middleware: permission,
                        authRequired: true,
                    }
                },
                //client
                {
                    path: 'clients',
                    name: 'client.getList',
                    component: Clients,
                    meta: {
                        middleware: permission,
                        authRequired: true,
                    }
                },
                {
                    path: 'clients/:id',
                    name: 'client.get',
                    component: ClientView,
                    meta: {
                        middleware: permission,
                        authRequired: true,
                    }
                },
                //coupon
                {
                    path: 'coupons',
                    name: 'coupon.getList',
                    component: CouponIndex,
                    meta: {
                        middleware: permission,
                        authRequired: true,
                    }
                },
            ]
        },
        {
            path: '/admin/login',
            name: 'admin.login',
            component: Login,
            meta: {
                middleware: guest,
                authRequired: false,
            }
        },
        {
            path: '/',
            component: () => import('@/layouts/portal/Main'),
            children: [
                {
                    path: '',
                    name: 'portal.home',
                    component: PortalHome,
                    meta: {
                        middleware: auth,
                        authRequired: false,
                    }
                },
                {
                    path: '/send-application/:type',
                    name: 'portal.send-application',
                    component: PortalSendApp,
                    meta: {
                        middleware: auth,
                        authRequired: false,
                    }
                },
                {
                    path: '/login',
                    name: 'portal.login',
                    component: PortalLogin,
                    meta: {
                        middleware: portalGuest,
                        authRequired: false,
                    }
                },
                {
                    path: '/register',
                    name: 'portal.register',
                    component: PortalRegister,
                    meta: {
                        middleware: portalGuest,
                        authRequired: false,
                    }
                },
                {
                    path: '/register/shipper',
                    name: 'portal.company.register',
                    component: PortalRegisterShipper,
                    meta: {
                        middleware: portalPermission,
                        authRequired: true,
                    }
                },
                {
                    path: '/register/portal',
                    name: 'portal.register.agent',
                    component: PortalRegisterAgent,
                    meta: {
                        middleware: portalGuest,
                        authRequired: false,
                    }
                },
                {
                    path: '/register/client',
                    name: 'portal.register.client',
                    component: PortalRegisterClient,
                    meta: {
                        middleware: portalGuest,
                        authRequired: false,
                    }
                },
                {
                    path: '/account',
                    name: 'portal.client.account',
                    component: PortalClientIndex,
                    meta: {
                        middleware: portalPermission,
                        authRequired: true,
                    }
                },
                {
                    path: '/profile',
                    name: 'portal.client.profile',
                    component: PortalClientProfile,
                    meta: {
                        middleware: portalPermission,
                        authRequired: true,
                    }
                },
                {
                    path: '/tracking/:type?',
                    name: 'portal.tracking',
                    component: PortalTracking,
                    meta: {
                        middleware: auth,
                        authRequired: false,
                    }
                },
                {
                    path: '/account/app-history',
                    name: 'portal.client.profile.apps',
                    component: PortalClientAppHistory,
                    meta: {
                        middleware: portalPermission,
                        authRequired: true,
                    }
                },
                {
                    path: '/403',
                    name: 'portal.errors.403',
                    component: PortalError403,
                    meta: {
                        middleware: auth,
                        authRequired: false,
                    }
                },
                {
                    path: '/404',
                    name: 'portal.errors.404',
                    component: PortalError404,
                    meta: {
                        middleware: auth,
                        authRequired: false,
                    }
                },
                {
                    path: '/about-us',
                    name: 'portal.aboutUs',
                    component: PortalAboutUs,
                    meta: {
                        authRequired: false,
                    }

                },
                {
                    path: '/forgot-password',
                    name: 'auth.forgotPassword',
                    component: ForgotPassword,
                    meta: {
                        authRequired: false,
                    }

                },
                {
                    path: '/reset-password',
                    name: 'auth.resetPassword',
                    component: ResetPassword,
                    meta: {
                        authRequired: false,
                    }

                },
                {
                    path: '*',
                    component: PortalError404,
                    meta: {
                        middleware: auth,
                        authRequired: false,
                    }
                },
            ]
        }
    ]
});

// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(context, middleware, index) {
    const subsequentMiddleware = middleware[index];
    // If no subsequent Middleware exists,
    // the default `next()` callback is returned.
    if ( !subsequentMiddleware ) return context.next;

    try {
        return (...parameters) => {
            // Run the default Vue Router `next()` callback first.
            context.next(...parameters);
            // Then run the subsequent Middleware with a new
            // `nextMiddleware()` callback.
            const nextMiddleware = nextFactory(context, middleware, index + 1);
            subsequentMiddleware({ ...context, next: nextMiddleware });
        };
    } catch ( e ) {

    }
}


router.beforeEach((to, from, next) => {

    if ( from.name !== to.name ) {
        store.dispatch('heading/setLastRoute', { name: from.name });
        store.dispatch('heading/flushHeadings');
        store.dispatch('heading/flushPortalHeadings');
    }

    if ( !to.meta )
        return next();

    // Handles middleware
    if ( to.meta.middleware ) {
        const middleware = Array.isArray(to.meta.middleware)
            ? to.meta.middleware
            : [ to.meta.middleware ];

        const context = {
            from,
            next,
            router,
            to,
        };
        try {
            const nextMiddleware = nextFactory(context, middleware, 1);
            return middleware[0]({ ...context, next: nextMiddleware });
        } catch ( e ) {

        }
    }

    return next();
});

export default router;