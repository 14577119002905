<template>
  <div>
    <router-view></router-view>
    <vue-snotify/>
    <svg-icons/>
  </div>
</template>

<script>

import PaceComponent from "@/components/PaceComponent";

import UserFunctions from "@/classes/UserFunctions";
import SvgIcons from "@/components/SvgIcons";


export default {
  name: 'App',
  components: { SvgIcons, PaceComponent },
  data() {
    return {
      locales: [],
      authenticated: true,
    }
  },
  created() {
    this.loadAxiosSettings();

    this.loadLocale();
    this.fetchLocales();

  },


  methods: {
    loadAxiosSettings() {

      this.axios.interceptors.request.use((config) => {

        this.$Pace.start();

        if ( !navigator.onLine ) {
          return this.snotifyError(this.$lang.app.noInternetConnection);
        }

        if ( config.headers.common['Authorization'] ) {

          if ( this.$route.meta.authRequired && !UserFunctions.getUserToken(!this.$route.path.includes('admin')) ) {
            config.headers.common.Authorization = null;
          }

        }

        return config;

      });

      this.axios.interceptors.response.use(response => {
        this.$Pace.finish();
        return response;
      }, error => {

        this.$Pace.fail();

        let errorObject = Object.assign({}, error);

        if ( !errorObject )
          return Promise.reject(error);

        let errorResponse = errorObject.response;

        if ( !errorResponse ) {
          return Promise.reject(error);
        }

        let errorStatus = errorResponse.status;

        if ( !errorResponse.hasOwnProperty('status') ) {

          if ( this.$route.path.includes('admin') )
            this.$router.push({ name: 'errors.500' }).catch(() => {
            });
          else
            this.$router.push({ name: 'portal.errors.500' }).catch(() => {
            });
        }
        switch ( errorStatus ) {
          case 401:

            if ( this.$route.path.includes('admin') ) {
              this.authenticated = false;
              UserFunctions.removeUserInfo();
            } else {
              this.authenticated = false;
              UserFunctions.removeUserInfo(true, true);
            }

            break;

          case 403:
            if ( this.$route.path.includes('admin') )
              this.$router.replace({ name: 'errors.403' }).catch(() => {
              });
            else
              this.$router.replace({ name: 'portal.errors.403' }).catch(() => {
              });
            break;
          case 404:
            let res = Object.assign({}, error).response;
            let query = {};
            if ( res && res.data.message ) {
              query.text = res.data.message;
            }
            if ( this.$route.path.includes('admin') )
              this.$router.replace({ name: 'errors.404', query: query }).catch(() => {
              });
            else
              this.$router.replace({ name: 'portal.errors.404', query: query }).catch(() => {
              });

            break;
          case 422:
            this.page422Handler(error);
            break;
          case 451:
            this.page451Handler(error);
            break;
          case 500:
            this.$snotify.error(errorResponse.data.message);
            break;
          case 429:
            this.$snotify.error('too many requests');
            break;
        }
        return Promise.reject(error);
      });
    },

    page451Handler(error) {
      let res = Object.assign({}, error).response;

      if ( res && res.data.message ) {
        let respData = res.data.data;

        let swalObject = {
          icon: 'error',
          title: this.getTranslation(res.data.message),
          allowOutsideClick: false
        };

        if ( respData.title )
          swalObject.title = this.getTranslation(respData.title);

        if ( respData.text )
          swalObject.text = this.getTranslation(respData.text);

        if ( respData.icon )
          swalObject.icon = this.getTranslation(respData.icon);

        Swal.fire(swalObject)
            .then((result) => {
              if ( result.isConfirmed ) {
                if ( respData.route )
                  this.$router.push(respData.route);
              } else if ( result.isDenied ) {

              }
            });
      }

    },
    page422Handler(error) {

      let res = Object.assign({}, error).response;

      if ( res ) {

        if ( res.data.errors ) {

          for ( let arr in res.data.errors ) {

            if ( res.data.errors.hasOwnProperty(arr) )
              for ( let msg of res.data.errors[arr] )
                this.$snotify.error(this.getTranslation(msg));
          }

        } else if ( res.data.data ) {

          for ( let arr in res.data.data ) {

            if ( res.data.data.hasOwnProperty(arr) )
              for ( let msg of res.data.data[arr] )
                this.$snotify.error(this.getTranslation(msg));
          }

        } else if ( res.data.message ) {
          this.$snotify.error(this.getTranslation(res.data.message));
        }
      }

    },
  }
}
</script>

