import moment from "moment";

export default {
    
    dateFormat( date ) {
        const dateParam = date;
        if ( !date ) return date;
        let dateTime = date.split( ' ' );
        if ( typeof dateTime[1] !== 'undefined' ) dateTime[1] = dateTime[1].split( ':' );
        if ( !( date instanceof Date ) ) date = new Date( dateTime[0] );
        if ( isNaN( date.getTime() ) ) return dateParam;
        date = date.toISOString().split( 'T' )[0].split( '-' );
        return date[2] + '.' + date[1] + '.' + date[0] + ( typeof dateTime[1] !== 'undefined' ? ' ' + dateTime[1][0] + ':' + dateTime[1][1] : '' );
    },
    
    getDuration( start, end ) {
        let endTime = moment( end, 'YYYY-MM-DD HH:mm' );
        let startTime = moment( start, 'YYYY-MM-DD HH:mm' );
        return moment.duration( endTime.diff( startTime ) ).asMinutes();
    },
    
    momentFormatDate( date, format = 'DD.MM.YYYY' ) {
        if ( !moment( date ).isValid() )
            return date;
        
        return moment( date ).format( format );
    },
    
    getTimeLeft( endTime, startTime = null, result = 'seconds' ) {
        
        let duration = 0;
        let end = moment( endTime, 'YYYY-MM-DD HH:mm' );
        
        if ( !startTime )
            duration = moment.duration( end.diff() );
        else {
            let start = moment( startTime, 'YYYY-MM-DD HH:mm' );
            duration = moment.duration( end.diff( start ) );
        }
        
        if ( result === 'hours' )
            return duration.asHours();
        if ( result === 'minutes' )
            return duration.asMinutes();
        
        return duration.asSeconds();
    }
}