const config = {
    locale: 'ru',
    baseUrl: 'http://api-cargo.local/api',
    // baseUrl: 'http://cargo-api.cargojet.kg/api',
    siteTitle: 'Cargo JET',
    logoUrl: '/images/logo-darkblue.jpg',
    originalLogoUrl: '/images/original-logo.png',
    noAvatarUrl: '/images/img_avatar-default.svg',
    noProfilePhotoUrl: '/images/no_profile_photo_user.png',
    noImageUrl: '/images/no-image.png',
    isProduction: false,
    websocketUrl: '',
    websocketHost: '127.0.0.1',
    websocketPort: 6001,
    websocketForceTLS: false,
    websocketAuthEndpoint: 'http://api-cargo.local/api/broadcasting/auth'
};

// don't change below code
switch ( location.hostname ) {
    case 'cargo.cargojet.kg':
        config.baseUrl = 'http://cargo-api.cargojet.kg/api';
        config.websocketUrl = 'cargo-api.cargojet.kg';
        config.websocketPort = 6001;
        config.websocketHost='cargo-api.cargojet.kg';
        config.websocketAuthEndpoint = 'http://cargo-api.cargojet.kg/api/broadcasting/auth';
        config.isProduction = true;
        config.websocketForceTLS = true;
        break;
    case 'cargojet.online':
    case 'www.cargojet.online':
        config.baseUrl = 'https://api.cargojet.online/api';
        config.websocketUrl = 'cargojet.online';
        config.websocketHost = 'cargojet.online';
        config.websocketPort = 6001;
        config.websocketAuthEndpoint = 'https://api.cargojet.online/api/broadcasting/auth';
        config.isProduction = true;
        config.websocketForceTLS = true;
        break;
    default:
        config.isProduction = false;
        break;
}

export default config