const authUrls = {
    login: {
        url: '/auth/login'
    },
    viewer: {
        url: '/auth/viewer'
    },
    logout: {
        url: '/auth/logout'
    },
    logins: {
        url: '/auth/logins'
    },
    statuses: {
        url: '/auth/statuses'
    },
    forgotPassword:{
        url:'/auth/forgotPassword'
    },
    resetPassword:{
        url:'/auth/resetPassword/:id'
    }
};

export default authUrls;