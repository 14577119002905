import store from "../store";
import HelperFunctions from '@/classes/HelperFunctions';

import UserFunctions from "@/classes/UserFunctions";

export default async ( { next, router, to } ) => {
    if ( !UserFunctions.getUserToken() )
        return  router.push( { name: 'admin.login' } ).catch(()=>{});
    
    if ( !store.getters['user/getUserId'] || store.getters['user/getPermissions'].length < 1 )
        await HelperFunctions.loadUser().then( () => {
            if ( store.getters['user/getPermissions'].filter( permission => permission === to.name ).length < 1 )
                return  router.push( { name: 'errors.403' } ).catch(()=>{});
        } ).catch( () => {
            return  router.push( { name: 'admin.login' } ).catch(()=>{});
        } );
    else if ( store.getters['user/getPermissions'].filter( permission => permission === to.name ).length < 1 )
        return router.push( { name: 'errors.403' } ).catch(()=>{});
    
    return next();
};
