const applicationUrls = {
    applications: {
        url: '/applications/'
    },
    application: {
        url: '/applications/:id'
    },
    applicationParcels: {
        url: '/applications/:id/parcels'
    },
    services: {
        url: '/applications/actions/services'
    },
    serviceOptions: {
        url: '/applications/actions/serviceOptions'
    },
    countries: {
        url: '/applications/actions/countries'
    },
    cities: {
        url: '/applications/actions/cities'
    },
    airports: {
        url: '/applications/actions/airports'
    },
    serviceParameters: {
        url: '/applications/actions/serviceParameters'
    },
    customsDocuments: {
        url: '/applications/actions/customsDocuments'
    },
    suitableCompanies: {
        url: '/applications/:id/suitableCompanies'
    },
    setCompanies: {
        url: '/applications/:id/setCompanies'
    },
    allCompanies: {
        url: '/applications/:id/allCompanies'
    },
    sendCompanies: {
        url: '/applications/:id/sendCompanies'
    },
    removeCompanies: {
        url: '/applications/:id/removeCompanies'
    },
    appCompanies: {
        url: '/applications/:id/companies'
    },
    formOptions: {
        url: '/applications/actions/formOptions'
    },
    contacts: {
        url: '/applications/:id/contacts'
    },
    savePrice: {
        url: '/applications/:id/savePrice'
    },
    currencies: {
        url: '/applications/actions/currencies'
    },
    airlines: {
        url: '/applications/actions/airlines'
    },
    selectCompany: {
        url: '/applications/:id/selectCompany'
    },
    confirm: {
        url: '/applications/:id/confirm'
    },
    payment: {
        url: '/applications/:id/payment'
    },
    getTrackingStatuses: {
        url: '/applications/actions/trackingStatuses'
    },
    addTrackingStatus: {
        url: '/applications/:id/addTrackingStatus'
    },
    tracking: {
        url: '/applications/:id/tracking'
    },
    requestedTarifs: {
        url: '/applications/:id/requestedTarifs'
    },
    totalParameters: {
        url: '/applications/:id/totalParameters'
    },
    reports: {
        url: '/applications/reports'
    },
    addContact:{
        url:'/applications/:id/addContact'
    },
    addDocument:{
        url:'/applications/:id/addDocument'
    },
    addAddress:{
        url:'/applications/:id/addAddress'
    },
    addParcel:{
        url:'/applications/:id/addParcel'
    },
    invoice:{
        url:'/applications/:id/invoice'
    },
    requestCallbacks:{
        url:'/applications/requestCallbacks'
    },
    requestCallbackContact:{
        url:'/applications/requestCallbacks/:id/contacted'
    },

    appCompany: {
        addPrice: {
            url: '/applications/appCompanies/:id/addPrice'
        },
        addExtraPrice: {
            url: '/applications/appCompanies/:id/addExtraPrice'
        },
        addShipment: {
            url: '/applications/appCompanies/:id/addShipment'
        },
        shipments: {
            url: '/applications/appCompanies/:id/shipments'
        },
        prices: {
            url: '/applications/appCompanies/:id/prices'
        },
        extraPrices: {
            url: '/applications/appCompanies/:id/extraPrices'
        }
    }
};

export default applicationUrls;