import store from '../store';
import config from '../config';
import axios from 'axios';
import urls from "@/urls/config";
import UserFunctions from "@/classes/UserFunctions";

export default {

    loadUser(force = false) {
        let token = UserFunctions.getUserToken();

        if ( token ) {

            UserFunctions.setAxiosAuthorization();

            if ( !store.getters['user/getUserId'] || force )
                return axios.get(urls.auth.viewer.url).then(response => {
                    store.dispatch('user/setUser', response.data.data);
                    return response.data.data;
                }).catch(() => {
                    return UserFunctions.removeUserInfo();
                });

        } else {
            return UserFunctions.removeUserInfo();
        }

        return new Promise(resolve => true);
    },

    loadPortalUser(force = false) {

        let token = UserFunctions.getUserToken(true);

        if ( token ) {

            UserFunctions.setAxiosAuthorization(true);

            if ( !store.getters['user/getPortalUserId'] || force )
                return axios.get(urls.auth.viewer.url).then(response => {
                    store.dispatch('user/setPortalUser', response.data.data);
                    return response.data.data;
                }).catch(() => {
                    return UserFunctions.removeUserInfo(true, true);
                });

        } else {
            return UserFunctions.removeUserInfo(true, true);
        }

        return new Promise(resolve => true);
    },


    getTranslation(key, count) {
        if ( !count ) count = 0;
        if ( this.translations && this.translations.hasOwnProperty(key) ) {
            if ( this.translations[key] &&
                this.translations[key].includes('{0}') )
                return this.translations[key].replace('{0}', count);
            return this.translations[key];
        } else if ( this.$lang ) {

            if ( this.$lang.languages[key] && this.$lang.languages[key].includes('{0}') )
                return this.$lang.languages[key].replace('{0}', count);

            return this.$lang.languages[key] || key;
        } else
            return key;
    },

    capitalizeFLetter(str) {
        return str[0].toUpperCase() +
            str.slice(1);
    },

    setHeadings(headings) {

        if ( headings.title ) {
            let title = this.getTranslation(headings.title) + ' - ' + this.getTranslation(config.siteTitle);
            this.setDocumentTitle(title);
        } else
            this.setDocumentTitle();

        this.$store.dispatch('heading/setHeadings', headings);

    },

    setPortalHeadings(headings) {

        if ( headings.title ) {
            let title = this.getTranslation(headings.title) + ' - ' + this.getTranslation(config.siteTitle);
            this.setDocumentTitle(title);
        } else
            this.setDocumentTitle();

        this.$store.dispatch('heading/setPortalHeadings', headings);

    },

    setDocumentTitle(title) {
        document.title = title || this.getTranslation(config.siteTitle);
    },

    getImageInfo(image) {

        return new Promise((resolve => {
            let imageInfo = {
                name: '',
                extension: '',
                base64: '',
                height: '',
                width: '',
                type: '',
                imageUrl: '',
                thumb: '',
                src: '',
                caption: ''
            };
            imageInfo.name = image.name;
            imageInfo.caption = image.name;
            imageInfo.extension = image.name.split('.').pop();

            let fileType = '';
            if ( imageInfo.extension === 'jpg' )
                fileType = 'jpeg';
            else
                fileType = imageInfo.extension.toLowerCase();

            imageInfo.type = fileType;

            let fileReader = new FileReader();

            fileReader.readAsDataURL(image);

            fileReader.onload = evt => {
                let img = new Image();
                img.onload = () => {
                    imageInfo.height = img.height;
                    imageInfo.width = img.width;
                };
                img.src = evt.target.result;

                imageInfo.imageUrl = evt.target.result;
                imageInfo.src = evt.target.result;
                imageInfo.thumb = evt.target.result;
                imageInfo.base64 = evt.target.result.replace(
                    'data:image/' + fileType + ';base64,', '');

                return resolve(imageInfo);
            }
            ;
            fileReader.onerror = evt => {
                console.error(evt);
            };

        }));
    },

    replaceBetween(str, start, end, what) {
        return str.substring(0, start) + what + str.substring(end);
    },

    /*snotify controller*/
    snotifySuccess(msg, timer = 2000,config={}) {

        config.timeout = timer;

        if ( !msg )
            return this.$snotify.success(this.getTranslation('saveSuccessMsg'),
                config);

        if ( !this.getTranslation(msg) )
            return this.$snotify.success(msg, config);

        return this.$snotify.success(this.getTranslation(msg),config);
    },

    snotifyError(msg, count = null, timer = 2000) {
        if ( !msg )
            return this.$snotify.error(this.getTranslation('serverError'), {
                timeout: timer,
            });
        if ( !this.getTranslation(msg) )
            return this.$snotify.error(msg, {
                timeout: timer,
            });

        return this.$snotify.error(this.getTranslation(msg, count), {
            timeout: timer,
        });
    },

    snotifyWarning(msg, count = null, timer = 2000) {
        if ( !msg )
            return this.$snotify.warning(this.getTranslation('warning', count),
                {
                    timeout: timer,
                });
        if ( !this.getTranslation(msg) )
            return this.$snotify.warning(msg, {
                timeout: timer,
            });

        return this.$snotify.warning(this.getTranslation(msg, count), {
            timeout: timer,
        });
    },

    objectToArray(obj, keyToInt, translate = false) {

        if ( typeof obj !== 'object' ) return obj;

        if ( translate )
            return Object.keys(obj).map(key => {
                return {
                    id: keyToInt ? Number.parseInt(key) : key,
                    title: this.getTranslation(obj[key]),
                };
            });

        return Object.keys(obj).map(key => {
            return {
                id: keyToInt ? Number.parseInt(key) : key,
                title: obj[key],
            };
        });
    },

    hasPortalPermission(permission) {

        if ( !permission )
            return false;

        if ( store.getters['user/getPortalPermissions'] )
            return store.getters['user/getPortalPermissions'].indexOf(permission) > -1;

        return false;
    },

    hasPermission(permission) {

        if ( !permission )
            return false;

        if ( store.getters['user/getPermissions'] )
            return store.getters['user/getPermissions'].indexOf(permission) > -1;

        return false;
    },

    makeId(length) {
        let result = '';
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789abcdefghijklmnopqrstuvwxyz';
        let charactersLength = characters.length;
        for ( let i = 0; i < length; i++ ) {
            result += characters.charAt(
                Math.floor(Math.random() * charactersLength));
        }
        return result;
    },


    cleanObject(object) {
        for ( let prop in object ) {
            if ( object.hasOwnProperty(prop) )
                if ( object[prop] === null || object[prop] === undefined ||
                    object[prop] === '' )
                    delete object[prop];
        }
        return object;
    },

    tabSelect(tabName, option = 'parcels', queryReplace = true) {
        tabName = tabName || this.tabs[this.$route.query.tab] || option;
        if ( !this.tabs[tabName] )
            for ( let tab in this.tabs ) {
                if ( this.tabs.hasOwnProperty(tab) ) {
                    this.tabs[tab] = tabName === tab;
                    if ( this.tabs[tab] === true ) {
                        if ( queryReplace ) {
                            let query = { ...this.$router.query };
                            query.tab = tab;
                            this.$router.replace({
                                query: query,
                            }).catch(() => {
                            });
                        }
                    }
                }
            }
    },


    loadLocale(localeParam=null) {

        let params = {
            lang: localeParam || this.getLocalStorageLocale(),
        };


        if ( !params.lang )
            params.lang = 'ru';

      return   axios.get(urls.language.translations.url, { params: this.cleanObject(params) }).then(response => {

            let lang = response.data.data;

            this.$store.dispatch('language/setLanguage', lang);

            this.setLocalStorageLocale(lang.locale);

        }).catch(() => {
        });

    },

    setLocalStorageLocale(locale){
      if ( !locale )
          locale='ru';

      localStorage.setItem('locale',locale);
    },

    getLocalStorageLocale(){
        return  localStorage.getItem('locale');
    }
,
    fetchLocales() {
        return this.axios.get(this.$urls.language.fetchLocales.url).then(response => {
            this.locales = response.data.data.locales;
            this.$store.dispatch('language/setLocales', response.data.data.locales);
        }).catch(() => {
        });
    },
};