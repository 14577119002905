<script>
    import VueSelect from 'vue-select';
    import 'vue-select/dist/vue-select.css';

    export default {
        extends: VueSelect,
        methods: {
            /**
             * Search 'input' KeyBoardEvent handler.
             * @param e {KeyboardEvent}
             * @return {Function}
             */
            onSearchKeyDown(e) {
                switch (e.keyCode) {
                    case 8:
                        //  delete
                        return this.maybeDeleteValue();
                    case 9:
                        //  tab
                        return this.onTab();
                    case 13:
                        //  enter.prevent
                        e.preventDefault();
                }
            }
        }
    }
</script>
