<template>
    <div>
        <svg style="display: none;" xmlns="http://www.w3.org/2000/svg">
            <defs>
                <clipPath id="icon__accesses-a">
                    <path fill="#fff" d="M0 0h24v24H0z" />
                </clipPath>
                <clipPath id="icon__favorites-a">
                    <path fill="#fff" d="M0 0h24v24H0z" />
                </clipPath>
                <clipPath id="icon__filter-a">
                    <path fill="#fff" d="M0 0h24v24H0z" />
                </clipPath>
                <clipPath id="icon__home-a">
                    <path fill="#fff" d="M0 0h24v24H0z" />
                </clipPath>
                <clipPath id="icon__layers-a">
                    <path fill="#fff" d="M0 0h24v24H0z" />
                </clipPath>
                <clipPath id="icon__mail-a">
                    <path fill="#fff" d="M0 0h24v24H0z" />
                </clipPath>
                <clipPath id="icon__pie-chart-a">
                    <path fill="#fff" d="M0 0h24v24H0z" />
                </clipPath>
                <clipPath id="icon__square-cross-a">
                    <path fill="#fff" d="M0 0h24v24H0z" />
                </clipPath>
                <clipPath id="icon__vacation-a">
                    <path fill="#fff" d="M0 0h60v60H0z" />
                </clipPath>
            </defs>
            <symbol viewBox="0 0 24 24" id="icon__accesses">
                <g clip-path="url(#icon__accesses-a)">
                    <path d="M13 1a1 1 0 1 0-2 0v6.083a6.04 6.04 0 0 1 2 0V1zM.327 22.26l6.622-6.02a6.033 6.033 0 0 0 1.346 1.48l-6.622 6.02a1 1 0 0 1-1.346-1.48zM22.327 23.74l-6.622-6.02a6.033 6.033 0 0 0 1.346-1.48l6.622 6.02a1 1 0 1 1-1.346 1.48z" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M16 13a4 4 0 1 1-8 0 4 4 0 0 1 8 0zm-2 0a2 2 0 1 1-4 0 2 2 0 0 1 4 0z" />
                </g>
            </symbol>
            <symbol viewBox="0 0 24 24" id="icon__add-user">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M1.464 17.465A5 5 0 0 1 5 16h7a5 5 0 0 1 5 5v2a1 1 0 1 1-2 0v-2a3 3 0 0 0-3-3H5a3 3 0 0 0-3 3v2a1 1 0 1 1-2 0v-2a5 5 0 0 1 1.464-3.535zM8.5 6a3 3 0 1 0 0 6 3 3 0 0 0 0-6zm-5 3a5 5 0 1 1 10 0 5 5 0 0 1-10 0zM20 0a1 1 0 0 1 1 1v6a1 1 0 1 1-2 0V1a1 1 0 0 1 1-1z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16 4a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2h-6a1 1 0 0 1-1-1z" />
            </symbol>
            <symbol viewBox="0 0 24 24" id="icon__arrow-2-left">
                <path d="M22.56 10.5H4.916l7.622-7.94a1.545 1.545 0 0 0 0-2.12 1.4 1.4 0 0 0-2.036 0L.422 10.94a1.545 1.545 0 0 0 0 2.12l10.08 10.5c.28.293.65.44 1.018.44s.737-.147 1.018-.44a1.544 1.544 0 0 0 0-2.12L4.916 13.5H22.56c.795 0 1.44-.672 1.44-1.5s-.645-1.5-1.44-1.5z" />
            </symbol>
            <symbol viewBox="0 0 24 24" id="icon__arrow-2-right">
                <path d="M1.428 13.5h17.696l-7.559 7.94a1.554 1.554 0 0 0 0 2.12 1.38 1.38 0 0 0 2.02 0l9.997-10.5a1.554 1.554 0 0 0 0-2.12L13.585.44a1.39 1.39 0 0 0-1.01-.44c-.365 0-.73.147-1.01.44a1.554 1.554 0 0 0 0 2.12l7.56 7.94H1.427C.64 10.5 0 11.172 0 12c0 .829.64 1.5 1.428 1.5z" />
            </symbol>
            <symbol viewBox="0 0 12 6" id="icon__arrow-down">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M.297.373C.733-.081 1.49-.127 1.99.27L6 3.46 10.01.27c.498-.397 1.257-.35 1.693.103.437.453.386 1.142-.113 1.539L6.79 5.73c-.452.36-1.128.36-1.58 0L.41 1.912C-.09 1.515-.14.826.297.372z" />
            </symbol>
            <symbol viewBox="0 0 6 12" id="icon__arrow-right">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M.373 11.703c-.454-.436-.5-1.194-.103-1.693L3.46 6 .27 1.99C-.127 1.491-.08.733.373.297.826-.14 1.515-.09 1.912.41l3.818 4.8c.36.452.36 1.128 0 1.58l-3.818 4.8c-.397.499-1.086.55-1.54.113z" />
            </symbol>
            <symbol viewBox="0 0 12 6" id="icon__arrow-up">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M.297 5.627c.436.454 1.194.5 1.693.103L6 2.54l4.01 3.19c.498.397 1.257.35 1.693-.103.437-.453.386-1.142-.113-1.539L6.79.27a1.292 1.292 0 0 0-1.58 0L.41 4.088c-.499.397-.55 1.086-.113 1.54z" fill="#2E71E5" />
            </symbol>
            <symbol viewBox="0 0 24 24" id="icon__bar-graph">
                <path d="M12 0a3.273 3.273 0 0 0-3.273 3.273v17.454a3.273 3.273 0 1 0 6.546 0V3.273A3.273 3.273 0 0 0 12 0zm1.09 20.727a1.09 1.09 0 0 1-2.18 0V3.273a1.09 1.09 0 0 1 2.18 0v17.454zm7.637-14.182a3.273 3.273 0 0 0-3.273 3.273v10.91a3.273 3.273 0 0 0 6.546 0V9.817a3.273 3.273 0 0 0-3.273-3.273zm1.091 14.182a1.091 1.091 0 0 1-2.182 0V9.818a1.09 1.09 0 0 1 2.182 0v10.91zM3.273 13.091A3.273 3.273 0 0 0 0 16.364v4.363a3.273 3.273 0 1 0 6.545 0v-4.363a3.273 3.273 0 0 0-3.272-3.273zm1.09 7.636a1.091 1.091 0 0 1-2.181 0v-4.363a1.091 1.091 0 0 1 2.182 0v4.363z" />
            </symbol>
            <symbol viewBox="0 0 24 24" id="icon__bell">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M12 2a6.7 6.7 0 0 0-6.7 6.701v5.501a4.3 4.3 0 0 1-.667 2.3h14.734a4.3 4.3 0 0 1-.667-2.3v-5.5A6.702 6.702 0 0 0 12 2zm11 14.503a2.3 2.3 0 0 1-2.3-2.3V8.7A8.702 8.702 0 0 0 12 0a8.7 8.7 0 0 0-8.7 8.701v5.501a2.301 2.301 0 0 1-2.3 2.3 1 1 0 1 0 0 2h22a1 1 0 1 0 0-2zM9.595 21.039a1 1 0 0 1 1.367.363 1.2 1.2 0 0 0 2.076 0 1 1 0 1 1 1.73 1.003 3.199 3.199 0 0 1-5.536 0 1 1 0 0 1 .363-1.366z" />
            </symbol>
            <symbol viewBox="0 0 128 97" id="icon__briefcase">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M52.364 0c-4.63 0-9.07 1.833-12.343 5.096A17.373 17.373 0 0 0 34.91 17.4v5.8H17.455C7.814 23.2 0 30.99 0 40.6v58c0 9.61 7.815 17.4 17.454 17.4h93.091c9.64 0 17.455-7.79 17.455-17.4v-58c0-9.61-7.815-17.4-17.455-17.4H93.091v-5.8c0-4.615-1.839-9.04-5.112-12.304A17.482 17.482 0 0 0 75.636 0H52.364zm29.09 23.2v-5.8a5.79 5.79 0 0 0-1.704-4.101 5.827 5.827 0 0 0-4.114-1.699H52.364a5.827 5.827 0 0 0-4.114 1.699 5.79 5.79 0 0 0-1.705 4.101v5.8h34.91zM46.546 34.8h34.908v69.6H46.546V34.8zm-11.637 0H17.455c-3.214 0-5.819 2.597-5.819 5.8v58c0 3.203 2.605 5.8 5.819 5.8h17.454V34.8zm58.182 69.6V34.8h17.454c3.214 0 5.819 2.597 5.819 5.8v58c0 3.203-2.605 5.8-5.819 5.8H93.091z" />
            </symbol>
            <symbol viewBox="0 0 24 24" id="icon__departments">
                <path d="M22 2H2v3H0V2a2 2 0 0 1 2-2h20a2 2 0 0 1 2 2v1h-2V2zM22 22H2v-1H0v1a2 2 0 0 0 2 2h20a2 2 0 0 0 2-2v-4h-2v4z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8 10a3 3 0 1 1-6 0 3 3 0 0 1 6 0zm-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                <path d="M3.133 14c-.778 0-1.553.256-2.148.752C.384 15.253 0 15.976 0 16.779V19a1 1 0 1 0 2 0v-2.222c0-.141.066-.323.265-.49A1.37 1.37 0 0 1 3.133 16h3.734c.353 0 .664.119.868.289.199.166.265.348.265.489V19a1 1 0 1 0 2 0v-2.222c0-.802-.384-1.525-.985-2.025C8.42 14.255 7.645 14 6.867 14H3.133z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M22 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0zm-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                <path d="M14.985 10.752c.595-.496 1.37-.752 2.148-.752h3.734c.778 0 1.553.256 2.148.752.601.501.985 1.224.985 2.026V15a1 1 0 1 1-2 0v-2.222c0-.142-.066-.323-.265-.49a1.37 1.37 0 0 0-.868-.288h-3.734a1.37 1.37 0 0 0-.868.289c-.199.166-.265.348-.265.489V15a1 1 0 1 1-2 0v-2.222c0-.802.383-1.525.985-2.026z" />
            </symbol>
            <symbol viewBox="0 0 24 24" id="icon__edit">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M19.707.293a1 1 0 0 0-1.414 0l-10 10A1 1 0 0 0 8 11v4a1 1 0 0 0 1 1h4a1 1 0 0 0 .707-.293l10-10a1 1 0 0 0 0-1.414l-4-4zM10 14v-2.586l9-9L21.586 5l-9 9H10zM2.423 2.423C2.694 2.153 3.061 2 3.444 2h6.527a1 1 0 0 0 0-2H3.444A3.444 3.444 0 0 0 0 3.444v17.112A3.444 3.444 0 0 0 3.444 24h17.112A3.444 3.444 0 0 0 24 20.556v-6.527a1 1 0 1 0-2 0v6.527A1.444 1.444 0 0 1 20.556 22H3.444A1.444 1.444 0 0 1 2 20.556V3.444c0-.383.152-.75.423-1.02z" />
            </symbol>
            <symbol viewBox="0 0 24 24" id="icon__favorites">
                <g clip-path="url(#icon__favorites-a)">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M12 0a1 1 0 0 1 .905.575l3.173 6.758 7.074 1.088a1 1 0 0 1 .563 1.687l-5.147 5.271 1.217 7.46a1 1 0 0 1-1.47 1.036L12 20.384l-6.314 3.491a1 1 0 0 1-1.47-1.036l1.216-7.46-5.147-5.271A1 1 0 0 1 .848 8.42l7.074-1.088L11.095.575A1 1 0 0 1 12 0zm0 3.353L9.506 8.666a1 1 0 0 1-.753.563l-5.679.873 4.142 4.242a1 1 0 0 1 .271.859l-.966 5.925 4.995-2.762a1 1 0 0 1 .968 0l4.996 2.762-.967-5.925a1 1 0 0 1 .271-.86l4.142-4.241-5.679-.873a1 1 0 0 1-.753-.563L12 3.353z" />
                </g>
            </symbol>
            <symbol viewBox="0 0 24 24" id="icon__file-text">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M2.96.93A3.276 3.276 0 0 1 5.25 0h9a1 1 0 0 1 .7.285l6.75 6.6a1 1 0 0 1 .3.715v13.2c0 .856-.348 1.672-.96 2.27a3.276 3.276 0 0 1-2.29.93H5.25a3.276 3.276 0 0 1-2.29-.93A3.175 3.175 0 0 1 2 20.8V3.2c0-.856.348-1.672.96-2.27zM5.25 2c-.339 0-.66.132-.892.36-.232.226-.358.53-.358.84v17.6c0 .31.126.614.358.84.233.228.553.36.892.36h13.5c.339 0 .66-.132.892-.36.232-.226.358-.53.358-.84V8.02L13.842 2H5.25z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M14 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2h-7a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1zM7 13a1 1 0 0 1 1-1h9a1 1 0 1 1 0 2H8a1 1 0 0 1-1-1zM7 18a1 1 0 0 1 1-1h9a1 1 0 1 1 0 2H8a1 1 0 0 1-1-1zM7 9a1 1 0 0 1 1-1h2a1 1 0 1 1 0 2H8a1 1 0 0 1-1-1z" />
            </symbol>
            <symbol viewBox="0 0 24 24" id="icon__filter">
                <g clip-path="url(#icon__filter-a)">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M.104.557A1 1 0 0 1 1 0h22a1 1 0 0 1 .794 1.607L15.2 12.85V23a1 1 0 0 1-1.45.893l-4.4-2.223a1 1 0 0 1-.55-.892V12.85L.206 1.607A1 1 0 0 1 .104.557zM3.024 2l7.57 9.904a1 1 0 0 1 .206.607v7.652l2.4 1.212V12.51a1 1 0 0 1 .206-.607L20.976 2H3.024z" />
                </g>
            </symbol>
            <symbol viewBox="0 0 24 24" id="icon__grid">
                <path d="M7.636 0H3.273A3.272 3.272 0 0 0 0 3.273v4.363a3.272 3.272 0 0 0 3.273 3.273h4.363a3.272 3.272 0 0 0 3.273-3.273V3.273A3.272 3.272 0 0 0 7.636 0zm1.091 7.636a1.09 1.09 0 0 1-1.09 1.091H3.272a1.09 1.09 0 0 1-1.091-1.09V3.272c0-.603.488-1.091 1.09-1.091h4.364c.603 0 1.091.488 1.091 1.09v4.364zm12-7.636h-4.363a3.272 3.272 0 0 0-3.273 3.273v4.363a3.272 3.272 0 0 0 3.273 3.273h4.363A3.272 3.272 0 0 0 24 7.636V3.273A3.272 3.272 0 0 0 20.727 0zm1.091 7.636a1.09 1.09 0 0 1-1.09 1.091h-4.364a1.09 1.09 0 0 1-1.091-1.09V3.272c0-.603.488-1.091 1.09-1.091h4.364c.603 0 1.091.488 1.091 1.09v4.364zm-1.09 5.455h-4.364a3.272 3.272 0 0 0-3.273 3.273v4.363A3.272 3.272 0 0 0 16.364 24h4.363A3.272 3.272 0 0 0 24 20.727v-4.363a3.272 3.272 0 0 0-3.273-3.273zm1.09 7.636a1.09 1.09 0 0 1-1.09 1.091h-4.364a1.09 1.09 0 0 1-1.091-1.09v-4.364c0-.603.488-1.091 1.09-1.091h4.364c.603 0 1.091.488 1.091 1.09v4.364zM7.636 13.091H3.273A3.272 3.272 0 0 0 0 16.364v4.363A3.272 3.272 0 0 0 3.273 24h4.363a3.272 3.272 0 0 0 3.273-3.273v-4.363a3.272 3.272 0 0 0-3.273-3.273zm1.091 7.636a1.09 1.09 0 0 1-1.09 1.091H3.272a1.09 1.09 0 0 1-1.091-1.09v-4.364c0-.603.488-1.091 1.09-1.091h4.364c.603 0 1.091.488 1.091 1.09v4.364z" />
            </symbol>
            <symbol viewBox="0 0 24 24" id="icon__home">
                <g clip-path="url(#icon__home-a)" fill-rule="evenodd" clip-rule="evenodd">
                    <path d="M11.427.18a1 1 0 0 1 1.146 0l11 7.7A1 1 0 0 1 24 8.7v12.1c0 .885-.391 1.709-1.047 2.299a3.588 3.588 0 0 1-2.397.901H3.444a3.588 3.588 0 0 1-2.397-.901A3.094 3.094 0 0 1 0 20.8V8.7a1 1 0 0 1 .427-.82l11-7.7zM2 9.22V20.8c0 .282.124.577.385.812a1.59 1.59 0 0 0 1.06.388h17.11c.416 0 .795-.15 1.06-.388.261-.235.385-.53.385-.812V9.22l-10-7-10 7z" />
                    <path d="M7 12a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v10a1 1 0 1 1-2 0v-9H9v9a1 1 0 1 1-2 0V12z" />
                </g>
            </symbol>
            <symbol viewBox="0 0 24 24" id="icon__in-out">
                <path d="M15.823 10.8V4.097l-1.815 1.851a1.16 1.16 0 0 1-1.663 0 1.217 1.217 0 0 1 0-1.697l3.823-3.9a1.16 1.16 0 0 1 1.664 0l3.823 3.9c.23.235.345.542.345.85 0 .306-.115.613-.345.847a1.16 1.16 0 0 1-1.663 0l-1.816-1.851V10.8c0 .662-.526 1.2-1.176 1.2-.65 0-1.177-.537-1.177-1.2zM5.823 13.2v6.703l-1.815-1.851a1.16 1.16 0 0 0-1.663 0 1.217 1.217 0 0 0 0 1.697l3.823 3.9a1.16 1.16 0 0 0 1.664 0l3.823-3.9c.23-.235.345-.542.345-.85 0-.306-.115-.613-.345-.847a1.16 1.16 0 0 0-1.663 0l-1.816 1.851V13.2c0-.662-.526-1.2-1.176-1.2-.65 0-1.177.537-1.177 1.2z" />
            </symbol>
            <symbol viewBox="0 0 24 24" id="icon__layers">
                <g clip-path="url(#icon__layers-a)" fill-rule="evenodd" clip-rule="evenodd">
                    <path d="M11.553.106a1 1 0 0 1 .894 0l11 5.5a1 1 0 0 1 0 1.788l-11 5.5a1 1 0 0 1-.894 0l-11-5.5a1 1 0 0 1 0-1.788l11-5.5zM3.236 6.5L12 10.882 20.764 6.5 12 2.118 3.236 6.5zM.09 17.586a1 1 0 0 1 1.324-.496L12 21.9l10.586-4.811a1 1 0 0 1 .828 1.82l-11 5a1 1 0 0 1-.828 0l-11-5a1 1 0 0 1-.496-1.324z" />
                    <path d="M.122 11.521a1 1 0 0 1 1.357-.399L12 16.861l10.521-5.739a1 1 0 0 1 .958 1.756l-11 6a1 1 0 0 1-.958 0l-11-6a1 1 0 0 1-.399-1.357z" />
                </g>
            </symbol>
            <symbol viewBox="0 0 24 24" id="icon__log-out">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M3.333 2c-.338 0-.672.14-.926.406C2.15 2.674 2 3.046 2 3.444v17.112c0 .397.151.77.407 1.038.254.266.588.406.926.406H8a1 1 0 1 1 0 2H3.333A3.28 3.28 0 0 1 .96 22.974c-.62-.649-.96-1.52-.96-2.418V3.444c0-.898.34-1.77.96-2.419A3.28 3.28 0 0 1 3.333 0H8a1 1 0 0 1 0 2H3.333zM16.293 5.293a1 1 0 0 1 1.414 0l6 6a1 1 0 0 1 0 1.414l-6 6a1 1 0 0 1-1.414-1.414L21.586 12l-5.293-5.293a1 1 0 0 1 0-1.414z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7 12a1 1 0 0 1 1-1h15a1 1 0 1 1 0 2H8a1 1 0 0 1-1-1z" />
            </symbol>
            <symbol viewBox="0 0 24 24" id="icon__mail">
                <g clip-path="url(#icon__mail-a)">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M.047 4.697a.999.999 0 0 0-.043.392A3.3 3.3 0 0 0 0 5.25v13.5C0 20.518 1.417 22 3.2 22h17.6c1.783 0 3.2-1.482 3.2-3.25V5.25c0-.054-.001-.108-.004-.161a1 1 0 0 0-.043-.392C23.694 3.183 22.393 2 20.8 2H3.2C1.606 2 .306 3.183.047 4.697zm2.122-.084A1.204 1.204 0 0 1 3.199 4h17.6c.426 0 .818.242 1.032.613L12 11.763l-9.831-7.15zm19.83 2.35V18.75c0 .706-.562 1.25-1.2 1.25H3.2c-.637 0-1.2-.544-1.2-1.25V6.964l9.412 6.845a1 1 0 0 0 1.176 0L22 6.964z" />
                </g>
            </symbol>
            <symbol viewBox="0 0 24 24" id="icon__menu">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2 12a1 1 0 0 1 1-1h18a1 1 0 1 1 0 2H3a1 1 0 0 1-1-1zM2 6a1 1 0 0 1 1-1h18a1 1 0 1 1 0 2H3a1 1 0 0 1-1-1zM2 18a1 1 0 0 1 1-1h18a1 1 0 1 1 0 2H3a1 1 0 0 1-1-1z" />
            </symbol>
            <symbol viewBox="0 0 24 24" id="icon__more-horizontal">
                <path d="M4 10a2 2 0 1 0 0 4 2 2 0 0 0 0-4zM10 12a2 2 0 1 1 4 0 2 2 0 0 1-4 0zM18 12a2 2 0 1 1 4 0 2 2 0 0 1-4 0z" />
            </symbol>
            <symbol viewBox="0 0 16 16" id="icon__more-vertical">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M4.667 8a1.333 1.333 0 1 1 2.666 0 1.333 1.333 0 0 1-2.666 0zM4.667 3.333a1.333 1.333 0 1 1 2.666 0 1.333 1.333 0 0 1-2.666 0zM4.667 12.667a1.333 1.333 0 1 1 2.666 0 1.333 1.333 0 0 1-2.666 0zM8.667 8a1.333 1.333 0 1 1 2.666 0 1.333 1.333 0 0 1-2.666 0zM8.667 3.333a1.333 1.333 0 1 1 2.666 0 1.333 1.333 0 0 1-2.666 0zM8.667 12.667a1.333 1.333 0 1 1 2.666 0 1.333 1.333 0 0 1-2.666 0z" />
            </symbol>
            <symbol viewBox="0 0 24 24" id="icon__notification">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M12 2a6.7 6.7 0 0 0-6.7 6.701v5.501a4.3 4.3 0 0 1-.667 2.3h14.734a4.3 4.3 0 0 1-.667-2.3v-5.5A6.702 6.702 0 0 0 12 2zm11 14.503a2.3 2.3 0 0 1-2.3-2.3V8.7A8.702 8.702 0 0 0 12 0a8.7 8.7 0 0 0-8.7 8.701v5.501a2.301 2.301 0 0 1-2.3 2.3 1 1 0 1 0 0 2h22a1 1 0 1 0 0-2zM9.595 21.039a1 1 0 0 1 1.367.363 1.2 1.2 0 0 0 2.076 0 1 1 0 1 1 1.73 1.003 3.199 3.199 0 0 1-5.536 0 1 1 0 0 1 .363-1.366z" />
            </symbol>
            <symbol viewBox="0 0 24 24" id="icon__phone">
                <path d="M23 17.471v3.312a2.202 2.202 0 0 1-.717 1.636 2.213 2.213 0 0 1-1.695.572 21.919 21.919 0 0 1-9.546-3.39 21.549 21.549 0 0 1-6.637-6.623 21.82 21.82 0 0 1-3.396-9.571 2.204 2.204 0 0 1 1.31-2.219c.28-.123.584-.188.891-.188H6.53A2.215 2.215 0 0 1 8.74 2.9c.14 1.06.4 2.1.774 3.102a2.204 2.204 0 0 1-.497 2.33L7.613 9.731a17.68 17.68 0 0 0 6.637 6.624l1.405-1.402a2.213 2.213 0 0 1 2.333-.497c1.004.374 2.047.633 3.109.773a2.213 2.213 0 0 1 1.902 2.241z"
                      stroke="#505050" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </symbol>
            <symbol viewBox="0 0 24 24" id="icon__pie-chart">
                <g clip-path="url(#icon__pie-chart-a)" fill-rule="evenodd" clip-rule="evenodd">
                    <path d="M8.636 1.56a1.091 1.091 0 0 1-.563 1.437A9.818 9.818 0 0 0 2.63 14.932a9.821 9.821 0 0 0 11.277 6.699 9.818 9.818 0 0 0 7.136-5.809 1.09 1.09 0 1 1 2.01.85 12.003 12.003 0 0 1-14.509 6.82A12 12 0 0 1 2.607 4.528 12 12 0 0 1 7.2.997a1.09 1.09 0 0 1 1.436.563z" />
                    <path d="M11.229.32A1.09 1.09 0 0 1 12 0a11.997 11.997 0 0 1 11.087 7.41c.603 1.456.913 3.016.913 4.592 0 .603-.488 1.092-1.09 1.092H12c-.602 0-1.09-.489-1.09-1.091V1.09c0-.29.114-.567.319-.771zm1.862 1.923v8.668h8.666a9.82 9.82 0 0 0-8.666-8.668z" />
                </g>
            </symbol>
            <symbol viewBox="0 0 60 60" id="icon__plane">
                <path d="M60 30.375c0-2.4-2.1-4.5-4.5-4.5H39l-15-24h-6l7.5 24H9l-4.5-6H0l3 10.5-3 10.5h4.5l4.5-6h16.5l-7.5 24h6l15-24h16.5c2.4 0 4.5-2.1 4.5-4.5z" />
            </symbol>
            <symbol viewBox="0 0 24 24" id="icon__posts">
                <g data-name="&#x421;&#x43B;&#x43E;&#x439; 2">
                    <g data-name="&#x421;&#x43B;&#x43E;&#x439; 1">
                        <path d="M23 19a1 1 0 0 1-1-1v-3a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v3a1 1 0 0 1-2 0v-3a3 3 0 0 1 3-3h4a3 3 0 0 1 3 3v3a1 1 0 0 1-1 1zM19 11a3 3 0 1 1 3-3 3 3 0 0 1-3 3zm0-4a1 1 0 1 0 1 1 1 1 0 0 0-1-1zM12 11h-2V5.75a4 4 0 0 0-.92-2.59A3.17 3.17 0 0 0 6.67 2h-.85a3 3 0 1 0 0 2h.85a1.16 1.16 0 0 1 .88.45A2 2 0 0 1 8 5.75V11H5.82a3 3 0 1 0 0 2H8v5.25a2 2 0 0 1-.45 1.3 1.16 1.16 0 0 1-.88.45h-.85a3 3 0 1 0 0 2h.85a3.19 3.19 0 0 0 2.41-1.16 4 4 0 0 0 .92-2.59V13h2a1 1 0 0 0 0-2zM3 4a1 1 0 1 1 1-1 1 1 0 0 1-1 1zm0 18a1 1 0 1 1 1-1 1 1 0 0 1-1 1z" />
                    </g>
                </g>
            </symbol>
            <symbol viewBox="0 0 22 22" id="icon__printer">
                <g data-name="&#x421;&#x43B;&#x43E;&#x439; 2">
                    <path d="M21.12 7.88A3 3 0 0 0 19 7h-1V1a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v6H3a3 3 0 0 0-3 3v5a3 3 0 0 0 3 3h1v3a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-3h1a3 3 0 0 0 3-3v-5a3 3 0 0 0-.88-2.12zM6 2h10v5H6zm10 18H6v-6h10zm4-5a1 1 0 0 1-1 1h-1v-3a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v3H3a1.05 1.05 0 0 1-.71-.29A1.05 1.05 0 0 1 2 15v-5a1.05 1.05 0 0 1 .29-.71A1.05 1.05 0 0 1 3 9h16a1 1 0 0 1 1 1z"
                          data-name="&#x421;&#x43B;&#x43E;&#x439; 1" />
                </g>
            </symbol>
            <symbol viewBox="0 0 24 24" id="icon__profile">
                <path d="M16.168 13.314A5.98 5.98 0 0 0 18 9V6A6 6 0 1 0 6 6v3c0 1.693.703 3.222 1.832 4.313C3.258 14.025 0 15.872 0 18.023V20a3 3 0 0 0 3 3h18a3 3 0 0 0 3-3v-1.969c0-2.151-3.26-4.003-7.832-4.717zM8 9V6a4 4 0 0 1 8 0v3a4 4 0 0 1-8 0zm14 11c0 .552-.448 1-1 1H3c-.551 0-1-.448-1-1v-1.977c0-.232.576-.985 2.288-1.703C6.289 15.481 9.1 15 12 15c2.899 0 5.709.482 7.71 1.323 1.713.72 2.29 1.474 2.29 1.708V20z" />
            </symbol>
            <symbol viewBox="0 0 24 24" id="icon__reload">
                <path d="M24 3.273v6.545a1.09 1.09 0 0 1-1.09 1.091h-6.546a1.09 1.09 0 0 1 0-2.182h3.678A604.185 604.185 0 0 1 17.08 5.83a8.698 8.698 0 0 0-6.171-2.556 8.698 8.698 0 0 0-6.171 2.556A8.699 8.699 0 0 0 2.182 12a8.698 8.698 0 0 0 2.556 6.171 8.698 8.698 0 0 0 6.171 2.556 8.698 8.698 0 0 0 6.171-2.556 8.694 8.694 0 0 0 2.06-3.261 1.09 1.09 0 1 1 2.057.726 10.864 10.864 0 0 1-2.574 4.078 10.841 10.841 0 0 1-5.631 2.996 10.973 10.973 0 0 1-6.187-.6 10.84 10.84 0 0 1-3.61-2.396A10.836 10.836 0 0 1 .2 14.083a10.98 10.98 0 0 1 0-4.166 10.84 10.84 0 0 1 2.996-5.631 10.842 10.842 0 0 1 5.63-2.995 10.961 10.961 0 0 1 6.188.599 10.84 10.84 0 0 1 3.609 2.396c.326.326 1.602 1.572 3.195 3.126v-4.14a1.09 1.09 0 0 1 2.182 0z" />
            </symbol>
            <symbol viewBox="0 0 24 24" id="icon__search">
                <path d="M23.68 22.133l-6.15-6.15a9.81 9.81 0 0 0 2.151-6.142C19.681 4.406 15.275 0 9.841 0 4.406 0 0 4.406 0 9.84c0 5.435 4.406 9.841 9.84 9.841a9.81 9.81 0 0 0 6.143-2.151l6.15 6.15a1.093 1.093 0 0 0 1.547-1.547zM9.84 17.494a7.654 7.654 0 1 1 0-15.307 7.654 7.654 0 0 1 0 15.307z" />
            </symbol>
            <symbol viewBox="0 0 24 24" id="icon__settings">
                <path d="M21 15a3 3 0 0 0 0-6h-.512a8.928 8.928 0 0 0-.365-.88l.362-.362a3 3 0 0 0-4.242-4.243l-.363.362a8.96 8.96 0 0 0-.88-.365V3a3 3 0 0 0-6 0v.512a8.94 8.94 0 0 0-.88.365l-.362-.362a3 3 0 0 0-4.243 4.243l.362.362c-.136.286-.258.579-.365.88H3a3 3 0 0 0 0 6h.512c.107.301.228.595.365.88l-.362.362a3 3 0 0 0 4.243 4.243l.362-.362c.286.136.579.258.88.365V21a3 3 0 0 0 6 0v-.512c.301-.107.595-.229.88-.365l.362.362a3 3 0 0 0 4.243-4.242l-.362-.363c.136-.286.258-.579.365-.88H21zm-2.07-2a6.968 6.968 0 0 1-1.323 3.192l1.425 1.426.039.039a1 1 0 0 1-1.414 1.414l-.04-.04-1.425-1.424A6.968 6.968 0 0 1 13 18.928V21a1 1 0 0 1-2 0v-2.07a6.968 6.968 0 0 1-3.192-1.323l-1.426 1.425-.039.039a1 1 0 0 1-1.414-1.414l.04-.04 1.425-1.425A6.967 6.967 0 0 1 5.07 13H3a1 1 0 0 1 0-2h2.07a6.968 6.968 0 0 1 1.324-3.192L4.968 6.382l-.039-.039A1 1 0 0 1 6.343 4.93l.04.04 1.425 1.425A6.967 6.967 0 0 1 11 5.07V3a1 1 0 0 1 2 0v2.07a6.967 6.967 0 0 1 3.192 1.324l1.426-1.426.039-.039a1 1 0 0 1 1.414 1.414l-.04.04-1.424 1.425A6.967 6.967 0 0 1 18.928 11H21a1 1 0 0 1 0 2h-2.07zM12 8a4 4 0 1 0 0 8 4 4 0 0 0 0-8zm0 6a2 2 0 1 1 0-4 2 2 0 0 1 0 4z" />
            </symbol>
            <symbol viewBox="0 0 24 24" id="icon__shape-add">
                <path d="M21 11h-8V3a1 1 0 0 0-2 0v8H3a1 1 0 0 0 0 2h8v8a1 1 0 1 0 2 0v-8h8a1 1 0 1 0 0-2z" />
            </symbol>
            <symbol viewBox="0 0 24 24" id="icon__shape-check">
                <path d="M9 19a1 1 0 0 1-.707-.293l-6-6a1 1 0 1 1 1.414-1.414L9 16.586 20.293 5.293a1 1 0 1 1 1.414 1.414l-12 12A1 1 0 0 1 9 19z" />
            </symbol>
            <symbol viewBox="0 0 24 24" id="icon__shape-cross">
                <path d="M13.414 12l6.293-6.293a1 1 0 1 0-1.414-1.414L12 10.586 5.707 4.293a1 1 0 0 0-1.414 1.414L10.586 12l-6.293 6.293a1 1 0 1 0 1.414 1.414L12 13.414l6.293 6.293a.997.997 0 0 0 1.414 0 1 1 0 0 0 0-1.414L13.414 12z" />
            </symbol>
            <symbol viewBox="0 0 24 24" id="icon__shape-minus">
                <path d="M21 11H3a1 1 0 0 0 0 2h18a1 1 0 1 0 0-2z" />
            </symbol>
            <symbol viewBox="0 0 60 60" id="icon__sick">
                <path d="M23.322 38.885h-1.787s-.326.017-.326-.234v-1.88a1.535 1.535 0 0 0-3.07 0v1.76c0 .372-.381.354-.381.354h-1.732a1.535 1.535 0 1 0 0 3.07h1.78s.333-.031.333.329v1.784a1.535 1.535 0 1 0 3.07 0V42.26c0-.3.385-.306.385-.306h1.728a1.535 1.535 0 1 0 0-3.07z" />
                <path d="M35.602 19.302l-6.695-2.966c-.49-.217-.97-.348-.97-1.492V9.155c0-.659.315-.858.462-.978a4.601 4.601 0 0 0 1.694-3.567A4.615 4.615 0 0 0 25.483 0H13.865a4.615 4.615 0 0 0-4.61 4.61 4.6 4.6 0 0 0 1.591 3.48c.178.154.567.426.567 1.003v5.738c0 1.047-.482 1.277-.972 1.494l-6.697 2.976c-1.335.593-2.341 1.328-2.341 3.603v34.13A2.97 2.97 0 0 0 4.368 60H34.98a2.97 2.97 0 0 0 2.966-2.966v-34.13c0-2.48-1.007-3.01-2.344-3.602zM24.08 47.854h-8.812c-4.206 0-7.628-3.423-7.628-7.629 0-4.206 3.422-7.628 7.628-7.628h8.812c4.206 0 7.628 3.422 7.628 7.628 0 4.206-3.422 7.629-7.628 7.629zm11.559-21.745c0 .824-1.12.78-1.347.78H5.012c-.227 0-1.303-.024-1.303-1.068v-2.917c0-1.039.48-1.277.971-1.495l6.697-2.976c1.335-.593 2.342-.907 2.342-3.602V9.835c0-.618.624-.615.673-.615h10.65c.049 0 .587-.065.587.574v5.05c0 2.737 1.008 3.01 2.344 3.602l6.695 2.965c.49.217.971.372.971 1.493v3.205zM50.213 26.848c-4.624 0-8.386 3.762-8.386 8.386 0 2.852 1.404 5.989 3.595 7.965.259.233.807.626.807 2.192v9.744a3.988 3.988 0 0 0 3.984 3.983 3.988 3.988 0 0 0 3.983-3.983V45.06c0-1.03.596-1.667.875-1.924 2.152-1.98 3.528-5.081 3.528-7.903 0-4.624-3.762-8.386-8.386-8.386zm4.477 13.696c-1.44 1.752-3.257 2.709-5.132 2.709-.057 0-.114-.001-.17-.003a1.154 1.154 0 0 1 .07-2.306l.1.001c1.577 0 2.772-1.163 3.35-1.866.997-1.212 1.618-2.781 1.662-4.198a1.16 1.16 0 0 1 1.189-1.117c.637.02 1.137.552 1.117 1.188-.058 1.91-.876 4-2.185 5.592z" />
            </symbol>
            <symbol viewBox="0 0 24 24" id="icon__skip-back">
                <path d="M22.54.44a1.452 1.452 0 0 1 0 2.12L12.65 12l9.889 9.44a1.452 1.452 0 0 1 0 2.12 1.625 1.625 0 0 1-2.222 0l-11-10.5a1.452 1.452 0 0 1 0-2.12l11-10.5c.307-.293.709-.44 1.11-.44.403 0 .805.147 1.112.44zM2.57 0C1.704 0 1 .672 1 1.5v21c0 .828.704 1.5 1.571 1.5.868 0 1.572-.672 1.572-1.5v-21C4.143.672 3.439 0 2.57 0z" />
            </symbol>
            <symbol viewBox="0 0 24 24" id="icon__skip-forward">
                <path d="M1.46 23.56a1.452 1.452 0 0 1 0-2.12L11.35 12 1.46 2.56a1.452 1.452 0 0 1 0-2.12 1.625 1.625 0 0 1 2.222 0l11 10.5a1.452 1.452 0 0 1 0 2.12l-11 10.5c-.307.293-.709.44-1.11.44-.403 0-.805-.147-1.112-.44zM21.43 24c.867 0 1.571-.672 1.571-1.5v-21c0-.828-.704-1.5-1.571-1.5-.868 0-1.572.672-1.572 1.5v21c0 .828.704 1.5 1.572 1.5z" />
            </symbol>
            <symbol viewBox="0 0 24 24" id="icon__square-add">
                <path d="M20.727 0H3.273A3.272 3.272 0 0 0 0 3.273v17.454A3.272 3.272 0 0 0 3.273 24h17.454A3.272 3.272 0 0 0 24 20.727V3.273A3.272 3.272 0 0 0 20.727 0zm1.091 20.727a1.09 1.09 0 0 1-1.09 1.091H3.272a1.09 1.09 0 0 1-1.091-1.09V3.272c0-.603.488-1.091 1.09-1.091h17.455c.603 0 1.091.488 1.091 1.09v17.455zm-5.454-9.818H13.09V7.636a1.09 1.09 0 0 0-2.182 0v3.273H7.636a1.09 1.09 0 0 0 0 2.182h3.273v3.273a1.09 1.09 0 1 0 2.182 0V13.09h3.273a1.09 1.09 0 1 0 0-2.182z" />
            </symbol>
            <symbol viewBox="0 0 24 24" id="icon__square-check">
                <path d="M20.727 0H3.273A3.272 3.272 0 0 0 0 3.273v17.454A3.272 3.272 0 0 0 3.273 24h17.454A3.272 3.272 0 0 0 24 20.727V3.273A3.272 3.272 0 0 0 20.727 0zm1.091 20.727a1.09 1.09 0 0 1-1.09 1.091H3.272a1.09 1.09 0 0 1-1.091-1.09V3.272c0-.603.488-1.091 1.09-1.091h17.455c.603 0 1.091.488 1.091 1.09v17.455zM15.592 8.528l-4.628 4.63-2.314-2.315a1.091 1.091 0 0 0-1.543 1.543l3.085 3.085a1.091 1.091 0 0 0 1.543 0l5.4-5.4a1.09 1.09 0 1 0-1.543-1.543z" />
            </symbol>
            <symbol viewBox="0 0 24 24" id="icon__square-cross">
                <g clip-path="url(#icon__square-cross-a)">
                    <path d="M20.727 24H3.273A3.272 3.272 0 0 1 0 20.727V3.273A3.272 3.272 0 0 1 3.273 0h17.454A3.272 3.272 0 0 1 24 3.273v17.454A3.272 3.272 0 0 1 20.727 24zm1.091-20.727a1.09 1.09 0 0 0-1.09-1.091H3.272a1.09 1.09 0 0 0-1.091 1.09v17.455c0 .603.488 1.091 1.09 1.091h17.455a1.09 1.09 0 0 0 1.091-1.09V3.272zm-5.96 12.584a1.091 1.091 0 0 1-1.544 0L12 13.543l-2.314 2.314a1.091 1.091 0 0 1-1.543-1.543L10.457 12 8.143 9.686a1.091 1.091 0 0 1 1.543-1.543L12 10.457l2.314-2.314a1.091 1.091 0 0 1 1.543 1.543L13.543 12l2.314 2.314a1.092 1.092 0 0 1 0 1.543z" />
                </g>
            </symbol>
            <symbol viewBox="0 0 24 24" id="icon__square-minus">
                <path d="M20.727 0H3.273A3.272 3.272 0 0 0 0 3.273v17.454A3.272 3.272 0 0 0 3.273 24h17.454A3.272 3.272 0 0 0 24 20.727V3.273A3.272 3.272 0 0 0 20.727 0zm1.091 20.727a1.09 1.09 0 0 1-1.09 1.091H3.272a1.09 1.09 0 0 1-1.091-1.09V3.272c0-.603.488-1.091 1.09-1.091h17.455c.603 0 1.091.488 1.091 1.09v17.455zm-5.454-9.818H7.636a1.09 1.09 0 0 0 0 2.182h8.728a1.09 1.09 0 1 0 0-2.182z" />
            </symbol>
            <symbol viewBox="0 0 24 24" id="icon__star">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M12 0a1 1 0 0 1 .905.575l3.173 6.758 7.074 1.088a1 1 0 0 1 .563 1.687l-5.147 5.271 1.217 7.46a1 1 0 0 1-1.47 1.036L12 20.384l-6.314 3.491a1 1 0 0 1-1.47-1.036l1.216-7.46-5.147-5.271A1 1 0 0 1 .848 8.42l7.074-1.088L11.095.575A1 1 0 0 1 12 0zm0 3.353L9.506 8.666a1 1 0 0 1-.753.563l-5.679.873 4.142 4.242a1 1 0 0 1 .271.859l-.966 5.925 4.995-2.762a1 1 0 0 1 .968 0l4.996 2.762-.967-5.925a1 1 0 0 1 .271-.86l4.142-4.241-5.679-.873a1 1 0 0 1-.753-.563L12 3.353z" />
            </symbol>
            <symbol viewBox="0 0 24 24" id="icon__subdivisions">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M8 2a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2h-1v3h6a2 2 0 0 1 2 2v3h1a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2h-4a2 2 0 0 1-2-2v-4a2 2 0 0 1 2-2h1v-3H5v3h1a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-4a2 2 0 0 1 2-2h1v-3a2 2 0 0 1 2-2h6V8h-1a2 2 0 0 1-2-2V2zm2 0h4v4h-4V2zM2 18h4v4H2v-4zm20 0h-4v4h4v-4z" />
            </symbol>
            <symbol viewBox="0 0 24 24" id="icon__users">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M1.464 13.464A5 5 0 0 1 5 12h8a5 5 0 0 1 5 5v6a1 1 0 1 1-2 0v-6a3 3 0 0 0-3-3H5a3 3 0 0 0-3 3v6a1 1 0 1 1-2 0v-6a5 5 0 0 1 1.464-3.536zM9 2a3 3 0 1 0 0 6 3 3 0 0 0 0-6zM4 5a5 5 0 1 1 10 0A5 5 0 0 1 4 5zM19.032 12.88a1 1 0 0 1 1.218-.718A5 5 0 0 1 24 16.999V23a1 1 0 1 1-2 0v-6a3 3 0 0 0-2.25-2.902 1 1 0 0 1-.718-1.218zM15.031.882a1 1 0 0 1 1.217-.72 5 5 0 0 1 0 9.687 1 1 0 1 1-.496-1.938 3 3 0 0 0 0-5.812 1 1 0 0 1-.72-1.217z" />
            </symbol>
            <symbol viewBox="0 0 60 60" id="icon__vacation">
                <g clip-path="url(#icon__vacation-a)">
                    <path d="M37.038 13.453a6.726 6.726 0 1 0 0-13.453 6.726 6.726 0 0 0 0 13.453zM53.828 23.134c-6.01-.618-11.839-7.123-13.305-8.024a7.054 7.054 0 0 0-2.983-1.233s-.723-.15-1.471-.068c-.716.08-1.804.116-3.397 1.304-.116.072-.93.78-1.186 1.09-1.984 2.055-7.48 8.134-10.215 15.012a2.609 2.609 0 0 0 .532 2.753l-3.213 5.725-4.525-2.543a1.122 1.122 0 0 0-1.53.427L3.97 52.713a1.123 1.123 0 0 0 .43 1.53l3.426 1.926a2.571 2.571 0 1 0 4.268-.327l4.677-8.205 3.277-5.84 4.062-7.04a2.611 2.611 0 0 0 2.019-1.613c1.158-2.915 2.95-5.724 4.682-8.051l.816 9.377a4.55 4.55 0 0 0 1.233 2.755c-2.103 3.814-7.764 14.246-9.808 17.79a3.325 3.325 0 0 0 3.115 4.977 3.322 3.322 0 0 0 2.642-1.657c1.904-3.301 6.871-13.32 9.05-17.086 1.746 2.902 4.721 8.243 8.496 16.772a3.32 3.32 0 0 0 4.387 1.694 3.32 3.32 0 0 0 1.692-4.382c-4.451-10.065-7.73-15.652-9.545-18.594.718-.901 1.15-2.021 1.037-3.336l-.772-8.881c2.237 1.786 5.156 3.29 10.14 3.806a2.611 2.611 0 0 0 .534-5.194zm-43.81 35.313a.937.937 0 1 1 0-1.874.937.937 0 0 1 0 1.874z" />
                </g>
            </symbol>

        </svg>
    </div>
</template>
<script>
    export default {
        name: "SvgIcons"
    }
</script>
