const languageUrls = {
    languages:{
        url:'/lang/languages'
    },
    translations:{
        url:'/lang/translates'
    },
    fetchLanguage:{
        url:'/lang/languages/:id'
    },
    fetchLocales:{
        url:'/lang/formOptions'
    }
};

export default languageUrls;