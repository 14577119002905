import config from "../config";

import Vue from 'vue'
import 'pusher-js'
import Echo from 'laravel-echo'
import UserFunctions from "@/classes/UserFunctions";

Vue.prototype.$echo = new Echo({
    broadcaster: 'pusher',
    key: '20DENEME',
    cluster: 'mt1',
    wsHost: config.websocketHost,
    wsPort: config.websocketPort,
    forceTLS: config.websocketForceTLS,
    disableStats: true,
    authEndpoint: config.websocketAuthEndpoint,
    auth: {
        headers: {
            Authorization: UserFunctions.getUserToken(),
            Accept: 'application/json',
        },
    }
});

export default Vue.prototype.$echo;
